import './style.css';

import * as Sentry from '@sentry/react';
import MobileDetect from 'mobile-detect';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useObservable } from 'react-use-observable';
import { pluck, startWith, tap } from 'rxjs';

import { usePlayerContext } from '../../../../../../contexts/PlayerContext/hook';
import { useMenuSubtitles } from '../../../../../../hooks/subtitlesMenu';
import { EPlaybackRateMenu, EQualityMenu, ESubMenu, ISubMenuData } from '../../../../../../interfaces/menu';
import { ELanguages, TLanguage } from '../../../../../../interfaces/watch';
import { Actions, controlsService } from '../../../../../../services/controls';
import { Events, eventsService } from '../../../../../../services/events';
import { levelsService } from '../../../../../../services/levels';
import ExitMenu from '../../../../../icons/ExitMenu';
import QualityIcon from '../../../../../icons/Quality';
import SpeedIcon from '../../../../../icons/Speed';
import SubtitleIcon from '../../../../../icons/Subtitle';
import MenuItem from './MenuItem';
import SubMenu from './SubMenu';

const Menu: React.FC = () => {
  const [subMenuData, setSubMenuData] = useState<ISubMenuData>();
  const mobileDetect = new MobileDetect(window.navigator.userAgent);
  const { openMenu, setOpenMenu } = usePlayerContext();
  const [levels] = useObservable(() => levelsService.getLevels(), []);
  const isMobile = mobileDetect.mobile() && !mobileDetect.tablet();
  const isTablet = mobileDetect.tablet();

  const subtitles = useMenuSubtitles();

  const [playbackRate] = useObservable(() => eventsService.on<number>(Events.PLAYER_RATE_CHANGED).pipe(
    pluck('event'),
    startWith(1),
    tap((data) => data !== undefined && Sentry.setTag('playbackRate', data)),
  ), []);

  const [quality] = useObservable(() => controlsService.on<number>(Actions.CLICK_LEVEL_CHANGE).pipe(
    pluck('data'),
    tap((data) => data !== undefined && Sentry.setTag('quality', EQualityMenu[data])),
    startWith(-1)
  ), []);

  const [subtitle] = useObservable(() => controlsService.on<TLanguage>(Actions.CLICK_SUBTITLE_CHANGE).pipe(
    pluck('data'),
    startWith('' as TLanguage)
  ), [])

  const isCurrentSubtitleAvailable = useMemo(() => !!(subtitle && subtitles?.map(({ data }) => data).includes(subtitle)), [subtitle, subtitles])

  const handleSubMenuChange = useCallback((currentValue: number | TLanguage | string, currentMenu: number) => {
    const subMenuActions = [
      () => controlsService.registerAction({ name: Actions.CLICK_LEVEL_CHANGE, data: currentValue }),
      () => controlsService.registerAction({ name: Actions.CLICK_RATE_CHANGE, data: currentValue }),
      () => controlsService.registerAction({ name: Actions.CLICK_SUBTITLE_CHANGE, data: currentValue })
    ];

    subMenuActions[currentMenu]();
  }, []);

  const handleExitMenuOnMobile = useCallback(() => {
    setOpenMenu(false);
  }, [setOpenMenu])

  useEffect(() => {
    !openMenu && setSubMenuData(undefined)
  }, [openMenu])

  return (
    <div className={`menu ${openMenu ? 'menu--open' : ''} ${isMobile ? 'menu--mobile' : ''} ${isTablet ? 'menu--tablet' : ''}`}>
      {isMobile ?
        <button className='menu__exit' onClick={handleExitMenuOnMobile}>
          <ExitMenu />
        </button>
        : null
      }
      <div className={`group ${subMenuData ? 'group--move' : ''}`}>
        <div className={`group__items ${isMobile ? 'group__items--mobile' : ''}`}>
          {levels && typeof quality === 'number' &&
            <MenuItem
              subItems={levels}
              currentValue={EQualityMenu[quality]}
              icon={<QualityIcon />}
              name={'Qualidade'}
              setSubItem={(list) => setSubMenuData({ title: 'Qualidade', list, id: ESubMenu.quality, initialValue: quality })}
            />
          }

          {(typeof playbackRate === 'number') &&
            <MenuItem
              subItems={[
                { title: "0.25x", data: 0.25 },
                { title: "0.50x", data: 0.50 },
                { title: "0.75x", data: 0.75 },
                { title: "Normal", data: 1 },
                { title: "1.25x", data: 1.25 },
                { title: "1.50x", data: 1.50 },
                { title: "1.75x", data: 1.75 },
                { title: "2x", data: 2 }
              ]}
              currentValue={EPlaybackRateMenu[playbackRate]}
              icon={<SpeedIcon />}
              name={'Velocidade'}
              setSubItem={(list) => setSubMenuData({ title: 'Velocidade', list, id: ESubMenu.playbackRate, initialValue: playbackRate })}
            />
          }

          {subtitles && typeof subtitle === 'string' &&
            <MenuItem
              subItems={subtitles}
              currentValue={isCurrentSubtitleAvailable ? ELanguages[subtitle] : 'Desativado'}
              icon={<SubtitleIcon />}
              name={'Legendas/CC'}
              setSubItem={(list) => setSubMenuData({ title: 'Legendas/CC', list, id: ESubMenu.subtitle, initialValue: isCurrentSubtitleAvailable ? subtitle : '' })}
            />}
        </div>

        {!!subMenuData &&
          <SubMenu
            data={subMenuData}
            onChange={handleSubMenuChange}
            closeSubMenu={() => setSubMenuData(undefined)}
          />}
      </div>
    </div>
  )
}

export default Menu;

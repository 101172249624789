import './style.css';

import Hls from 'hls.js';
import { useEffect, useRef, useState } from 'react';
import { useObservable } from 'react-use-observable';

import { useActive } from '../../../../../hooks/active';
import { useBufferCatcher } from '../../../../../hooks/buffer';
import { useBufferControl } from '../../../../../hooks/bufferControl';
import { useBufferTime } from '../../../../../hooks/bufferTime';
import { useChromecast } from '../../../../../hooks/chromecast/chromecast';
import { useChromecastConnection } from '../../../../../hooks/chromecast/chromecastConnection';
import { useHlsErrorCatcher } from '../../../../../hooks/hlsErrorCatcher';
import { useHlsEventCatcher } from '../../../../../hooks/hlsEventCatcher';
import { useHlsFatalErrorHandler } from '../../../../../hooks/hlsFatalErrorHandler';
import { useLoading } from '../../../../../hooks/loading';
import { useWatchedRanges } from '../../../../../hooks/ranges';
import { useRemoteControl } from '../../../../../hooks/remoteControl';
import { useReplay } from '../../../../../hooks/replay';
import { useSubtitleSelector } from '../../../../../hooks/subtitleSelector';
import { useTrack } from '../../../../../hooks/track';
import { useVideoActions } from '../../../../../hooks/videoActions';
import { useVideoEventCatcher } from '../../../../../hooks/videoEventCatcher';
import { errorHandlerService } from '../../../../../services/errorHandler';
import PlayerService from '../../../../../services/player';
import { remoteControlService } from '../../../../../services/remoteControl';
import RemoteControlIndicator from './RemoteControlndicator';

interface IProps {
  playlistURL: string;
  seekTime: number;
  thumbnail: string;
  loop?: boolean;
  autoplay?: boolean;
}

const Video: React.VFC<IProps> = ({ playlistURL, seekTime, thumbnail, loop, autoplay }) => {
  const playerRef = useRef<HTMLVideoElement | null>(null);
  const [hls] = useState(new Hls({ startPosition: seekTime, capLevelToPlayerSize: true, nudgeMaxRetry: Number.MAX_SAFE_INTEGER }));

  const playerServiceRef = useRef(new PlayerService(playerRef));
  const [remoteControlActive] = useObservable(() => remoteControlService.getRemoteControlMode(), []);

  useVideoEventCatcher(playerRef);
  useVideoActions(playerServiceRef, playerRef);
  useHlsEventCatcher(hls);
  useHlsErrorCatcher(hls);
  useHlsFatalErrorHandler(hls);

  useWatchedRanges(playerRef);
  useLoading(playerRef);
  useBufferCatcher();
  useBufferTime(hls);
  useBufferControl(hls);
  useActive();
  useReplay();

  useChromecastConnection();
  useChromecast(playlistURL);
  useRemoteControl();

  useTrack(playerRef);
  useSubtitleSelector(playerRef);

  // TODO: create a hook for this
  useEffect(() => {
    const playerElement = playerRef.current;
    if (playerElement !== null) {
      if (Hls.isSupported()) {
        hls.loadSource(playlistURL);
        hls.attachMedia(playerElement);
      } else if (playerElement.canPlayType('application/vnd.apple.mpegurl')) {
        playerElement.src = playlistURL;
      } else {
        errorHandlerService.defineCurrentError({
          type: 'api',
          data: errorHandlerService.getErrorDescriptionByErrorCode(601)
        })
      }
    }
  }, [hls, playlistURL])

  return (
    <div className={`player__wrapper ${remoteControlActive ? 'player__wrapper--active' : ''}`}>
      <video controls={false} poster={thumbnail} ref={playerRef} loop={loop} autoPlay={autoplay}></video>
      <RemoteControlIndicator thumbnail={thumbnail} />
    </div>
  );
}

export default Video;

import { useEffect, useMemo } from 'react';
import { BehaviorSubject, fromEvent } from 'rxjs';

import { Actions, controlsService } from '../services/controls';
import { playPauseService } from '../services/playPause';

export const usePlayPauseShortcut = () => {
  const isPlaying$ = useMemo(() => new BehaviorSubject(false), []);

  useEffect(() => {
    const playPauseSubscription = playPauseService.listenIsPlaying().subscribe((isPlaying) => isPlaying$.next(isPlaying));
    return () => playPauseSubscription.unsubscribe();
  }, [isPlaying$])

  useEffect(() => {
    const playPauseSubscription = fromEvent<KeyboardEvent>(document, 'keydown').subscribe(data => {
      if (data.key.toLocaleLowerCase() === 'k' || data.key === ' ') {
        controlsService.registerAction({ name: isPlaying$.value ? Actions.CLICK_PAUSE : Actions.CLICK_PLAY })
      }
    });
    return () => playPauseSubscription.unsubscribe();
  }, [isPlaying$])
}

import { useEffect } from 'react';
import { fromEvent, pluck } from 'rxjs';

import { Actions, controlsService } from '../services/controls';
import { Events, eventsService } from '../services/events';
import PlayerService from '../services/player';

export const useSeekAction = ({ current: playerService }: React.MutableRefObject<PlayerService>) => {
  useEffect(() => {
    const seekSubscription = controlsService
      .on<number>(Actions.REQUEST_SEEK)
      .pipe(pluck('data'))
      .subscribe((newSeekTime) => {
        playerService.getCurrentPlayerService().seek(newSeekTime || 0);
      });

    return () => seekSubscription.unsubscribe();
  }, [playerService]);
}

export const useSeekEvents = (videoElementRef: React.MutableRefObject<HTMLVideoElement | null>) => {
  useEffect(() => {
    if (videoElementRef.current) {
      const seekedSubscription = fromEvent(videoElementRef.current, 'seeked').subscribe(data => {
        eventsService.registerEvent({
          name: Events.PLAYER_SEEKED,
          event: data
        })
      });

      return () => seekedSubscription.unsubscribe();
    }
  }, [videoElementRef]);
}

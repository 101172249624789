import * as Sentry from '@sentry/react';
import { map, pluck, take, tap } from 'rxjs/operators';

import { fancyTimeFormat } from '../helpers/dateTime';
import { Events, eventsService } from './events';
import { rangesService } from './ranges';
import { seekTimeService } from './seekTime';

class DurationService {
  getVideoCurrentTime() {
    return eventsService.on<number>(Events.PLAYER_TIMEUPDATE)
      .pipe(
        pluck('event'),
        tap(time => seekTimeService.setSeekTime(time || 0)),
        tap(time => rangesService.updateLastRange(time || 0)),
        map(time => fancyTimeFormat(time || 0)),
        tap((time) => Sentry.setTag('seekTime', time))
      );
  }

  getVideoDuration() {
    return eventsService.on<number>(Events.PLAYER_DURATIONCHANGE)
      .pipe(
        take(1),
        pluck('event'),
        map(time => fancyTimeFormat(time || 0))
      )
  }
}

export const durationService = new DurationService();

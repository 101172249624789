import { of } from 'rxjs';
import { pluck, switchMap } from 'rxjs/operators';

import { api } from './api';

interface ITestItem {
  id?: number;
  tokenKey?: string;
  title: string;
}
export type TListType = 'tokens' | 'directs' | 'embeds';

class TestService {
  getList(type: TListType) {
    return api.get<ITestItem[]>(`test/${type}`).pipe(
      switchMap(data => of(data))
    );
  }

  getPlayerToken(type: TListType, id: number) {
    return api.get<{ playerToken: string }>(`test/${type}/${id}`)
      .pipe(
        pluck('playerToken'),
        switchMap((data) => of(data))
      );
  }
}

export const testService = new TestService();
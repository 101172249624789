import React from 'react';

const LogoSvg: React.VFC = () => {
  return (
    <svg style={{ width: 'auto' }} version="1.1" id="Camada_1" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 109.1 24" xmlSpace="preserve">
      <g id="Layer_2">
        <g id="Layer_1-2">
          <g id="safevideo">
            <path fill="currentColor" d="M0,15.1c0-0.1,0-0.2,0.1-0.3l0.4-1.1c0-0.1,0.1-0.2,0.3-0.2l0.1,0c1,0.6,2.1,0.9,3.2,0.9c0.9,0,1.5-0.1,1.9-0.2
				c0.3-0.1,0.6-0.4,0.5-0.8c0-0.4-0.2-0.7-0.6-0.9c-0.5-0.3-1-0.5-1.6-0.6c-0.6-0.2-1.2-0.4-1.9-0.6c-0.6-0.2-1.2-0.6-1.6-1.1
				C0.3,9.8,0.1,9.1,0.1,8.4c0-1.1,0.4-1.8,1.2-2.3c0.9-0.5,1.8-0.8,2.9-0.8c1,0,2.1,0.2,3.1,0.4c0.1,0,0.2,0.2,0.2,0.3
				c0,0.1,0,0.2-0.1,0.3L7.1,7.5c0,0.1-0.1,0.2-0.2,0.2h0C6.1,7.5,5.4,7.4,4.6,7.4c-1,0-1.7,0.2-1.9,0.5C2.4,8.1,2.3,8.3,2.3,8.5
				c0,0.3,0.2,0.6,0.7,0.9C3.5,9.6,4,9.8,4.5,10c0.6,0.2,1.2,0.4,1.9,0.6C7,10.8,7.6,11.2,8,11.7c1,1.2,0.8,2.9-0.3,3.9c0,0,0,0,0,0
				l0,0c-0.7,0.6-1.8,0.9-3.4,0.9c-1.4,0-2.8-0.4-4-1.1C0.1,15.3,0,15.2,0,15.1z"/>
            <path fill="currentColor" d="M10.7,7.5L10.4,6c0-0.2,0.1-0.3,0.2-0.3c1-0.2,2-0.4,3-0.3c2.8,0,4.5,1.5,4.5,4.2v5.7c0,0.3-0.5,0.6-1.6,0.8
				c-0.9,0.2-1.7,0.4-2.6,0.4c-2.8,0-4.2-1.1-4.2-3.3c0-1.3,0.3-2.2,1-2.6s1.6-0.7,2.7-0.7h2.6V9.8c0-1.7-1-2.3-2.9-2.3
				c-0.7,0-1.4,0.1-2.1,0.2C10.8,7.7,10.7,7.6,10.7,7.5z M15.9,11.8h-1.8c-1.5,0-2.3,0.5-2.3,1.4c0,0.6,0.2,0.9,0.5,1.1
				s0.9,0.3,1.6,0.3c0.5,0,1-0.1,1.4-0.2c0.4-0.1,0.6-0.4,0.6-0.7L15.9,11.8z"/>
            <path fill="currentColor" d="M23.2,4.4v1h2c0.2,0,0.4,0.2,0.4,0.4v1.2c0,0.2-0.2,0.4-0.4,0.4h-2V16c0,0.2-0.2,0.4-0.4,0.4h-1.4
				c-0.2,0-0.4-0.2-0.4-0.4V7.5h-1.5c-0.2,0-0.4-0.2-0.4-0.4l0,0V5.9c0-0.2,0.2-0.4,0.4-0.4c0,0,0,0,0,0H21V4.3
				c0-2.5,1.1-3.8,3.4-3.8c0.4,0,0.7,0.1,1.1,0.1c0.2,0,0.3,0.2,0.3,0.4c0,0,0,0,0,0l0,0v1.2c0,0.3-0.1,0.4-0.3,0.4
				c-0.2,0-0.4,0-0.5,0C23.8,2.6,23.2,3.3,23.2,4.4z"/>
            <path fill="currentColor" d="M34.5,14.5l0.2,1.5c0,0.1-0.1,0.3-0.2,0.3c-1,0.2-2,0.3-3,0.3c-1.5,0-2.7-0.4-3.6-1.2c-0.9-0.8-1.4-2.1-1.4-3.8V10
				c0-0.3,0-0.6,0.1-0.9c0.1-0.4,0.2-0.8,0.3-1.2c0.2-0.4,0.4-0.9,0.7-1.2c0.6-0.7,1.8-1.3,3.4-1.3c1.6,0,2.9,0.6,3.5,1.3
				c0.3,0.4,0.6,0.8,0.7,1.3c0.3,0.7,0.4,1.4,0.4,2.2v1.5c0,0.2-0.2,0.4-0.4,0.4h-6.5v0.1c0.1,1,0.4,1.7,0.9,1.9
				c0.5,0.3,1.4,0.4,2.8,0.4c0,0,0.6-0.1,1.8-0.2C34.3,14.3,34.5,14.3,34.5,14.5z M33.5,10c0-1.4-0.6-2.1-1.3-2.4
				c-0.3-0.1-0.7-0.2-1.1-0.2c-1.6,0-2.4,0.9-2.4,2.6L33.5,10z"/>
            <path fill="currentColor" d="M67.8,16.3c-0.2,0-0.4-0.1-0.4-0.3L64.1,5.8c0-0.2,0.2-0.4,0.4-0.4h1.4c0.2,0,0.3,0.1,0.4,0.4l2.6,8.1l2.6-8.1
				c0.1-0.3,0.3-0.4,0.4-0.4h1.4c0.2,0,0.4,0.2,0.4,0.4L70.4,16c0,0.2-0.2,0.3-0.4,0.3H67.8z"/>
            <path fill="currentColor" d="M74.7,3.5c0-0.8,0.4-1.1,1.3-1.1c0.7,0,1.2,0.4,1.2,1.1s-0.5,1.1-1.2,1.1C75.1,4.7,74.7,4.3,74.7,3.5z M74.8,5.8
				c0-0.2,0.2-0.4,0.4-0.4h1.4c0.2,0,0.4,0.2,0.4,0.4v10.1c0,0.2-0.2,0.4-0.4,0.4h-1.4c-0.2,0-0.4-0.2-0.4-0.4L74.8,5.8z"/>
            <path fill="currentColor" d="M87.9,15.6c0,0.1-0.2,0.2-0.7,0.4c-0.6,0.2-1.2,0.3-1.8,0.4c-0.7,0.1-1.4,0.1-2.1,0.1c-3,0-4.5-1.9-4.5-5.8
				c0-1.9,0.5-3.4,1.3-4.2c0.8-0.8,1.7-1.2,2.6-1.2c1.1,0,2.1,0.2,3.1,0.5V2.7c0-0.2,0.2-0.4,0.4-0.4h1.4c0.2,0,0.4,0.2,0.4,0.4
				L87.9,15.6z M85.7,14.1V7.8c-0.7-0.2-1.4-0.3-2.2-0.4c-1.2,0-1.9,0.4-2.2,1.1s-0.4,1.3-0.4,2.3c0,0.4,0,0.7,0,1
				c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.2,0.6,0.4,0.9c0.3,0.4,1,0.8,2,0.8c0.3,0,0.6,0,1-0.1c0.3,0,0.6-0.1,0.9-0.1L85.7,14.1z"/>
            <path fill="currentColor" d="M97.6,14.4l0.2,1.5c0,0.1-0.1,0.2-0.2,0.3c-1,0.2-2,0.3-3,0.3c-1.5,0-2.7-0.4-3.6-1.2c-0.9-0.8-1.4-2.1-1.4-3.8V9.9
				c0-0.3,0-0.6,0.1-0.9c0.1-0.4,0.2-0.8,0.3-1.2c0.2-0.4,0.4-0.9,0.7-1.2c0.6-0.7,1.8-1.3,3.4-1.3c1.6,0,2.9,0.6,3.5,1.3
				c0.3,0.4,0.6,0.8,0.8,1.3c0.3,0.7,0.4,1.4,0.4,2.2v1.5c0,0.2-0.2,0.4-0.4,0.4h-6.5v0.1c0.1,1,0.4,1.7,0.9,1.9
				c0.5,0.3,1.4,0.4,2.8,0.4c0,0,0.6-0.1,1.8-0.2C97.4,14.2,97.5,14.3,97.6,14.4z M96.5,10c0-1.4-0.6-2.1-1.3-2.4
				c-0.3-0.1-0.7-0.2-1.1-0.2c-1.6,0-2.4,0.9-2.4,2.6L96.5,10z"/>
            <path fill="currentColor" d="M104.7,5.3c1.3,0,2.4,0.4,3.2,1.1c0.8,0.8,1.2,2,1.2,3.8v1.4c0,1.7-0.4,3-1.2,3.8c-0.8,0.8-1.9,1.1-3.2,1.1
				s-2.4-0.4-3.2-1.1c-0.8-0.8-1.2-2-1.2-3.7v-1.4c0-1.7,0.4-3,1.2-3.8C102.3,5.7,103.4,5.3,104.7,5.3z M104.7,7.4
				c-1.2-0.1-2.2,0.9-2.2,2.1c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2v2.4c0,1.2,1.1,2.2,2.3,2.2c1.2,0,2.2-1,2.2-2.2V9.7
				c0.1-1.2-0.8-2.2-2-2.3C104.8,7.4,104.8,7.4,104.7,7.4z"/>
          </g>
          <g id="Logo">
            <path fill="currentColor" d="M47.4,7c0,0,0.3-1.3,2-3.7C51.1,0.8,52,0.4,52,0.4l-1.7-0.5L39.9,3.2c0,0-0.3,3.3-0.3,5c0,1.8,0.1,3.7,0.4,5.5
				c0.8,0.7,1.7,1.3,2.7,1.7c2.8,1.2,4.8,1.1,4.8,1.1V7z"/>
            <path fill="currentColor" d="M52,0.4c0,0-0.9,0.3-2.8,3C47.6,5.8,47.4,7,47.4,7l7.9,5.2c0.7,1.1,1.2,2.2,1.5,3.5c0.3,1,0.6,4.3,0.6,4.3
				s1.8-1.6,2.7-3.3c0.9-1.8,1.1-5.8,1.1-8.1c0-1.8-0.3-5.3-0.3-5.3L52,0.4z"/>
            <path fill="currentColor" d="M39.9,13.7c0,0,2,1.4,3.6,2c1.2,0.4,2.5,0.7,3.8,0.8l7.9-4.3c0,0,0.5,0.4,1.4,2.6c1,2.5,0.7,5.2,0.7,5.2
				s-2.1,1.6-3.3,2.3c-1.3,0.7-3.7,1.7-3.7,1.7s-4.7-1.8-7.4-4.4C41.3,18,40.3,16,39.9,13.7z"/>
            <path fill="currentColor" d="M47.4,7c0,0,0.1-0.8,1.1-2.4c1.2-2.1,2.8-3.8,3.6-4.1" />
            <path fill="currentColor" d="M47.3,7c0,0,0.1-0.8,1.1-2.4c1.3-2.1,2.8-3.8,3.6-4.2" />
            <path fill="currentColor" d="M55.2,12.2c0,0,0.6,0.6,1.3,2.3c0.9,2.1,0.9,4.6,0.8,5.4" />
            <path fill="currentColor" d="M55.2,12.2c0.5,0.7,1,1.4,1.3,2.3c0.9,2.3,1,4.7,0.8,5.5" />
            <path fill="currentColor" d="M47.3,16.5c-3.5-0.1-6.5-2.1-7.5-2.8" />
            <path fill="currentColor" d="M47.4,16.5c-3.5-0.1-6.5-2-7.5-2.8" />
            <path fill="currentColor" d="M47.4,7c0,0,2.9-3.7,8.8-5.2L52,0.4C50.9,0.9,48,4.6,47.4,7z" />
            <path fill="currentColor" d="M55.2,12.2c0,0,1.1,4.7-1.6,10.2c1.2-0.5,2.9-1.9,3.7-2.5C57.6,17.1,56.6,13.9,55.2,12.2z" />
            <path fill="currentColor" d="M47.4,16.5c0,0-4.8-2-7.8-7.3c0,1.5,0.1,3.1,0.3,4.6C42,15.3,44.5,16.4,47.4,16.5z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LogoSvg;

import './style.css';

import MobileDetect from 'mobile-detect';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ESubMenu, ISubMenuData, ISubMenuItem } from '../../../../../../../interfaces/menu';
import { TLanguage } from '../../../../../../../interfaces/watch';
import ArrowLeftIcon from '../../../../../../icons/ArrowLeft';
import SubMenuItem from './SubMenuItem';

interface ISubMenuProps {
  data: ISubMenuData;
  closeSubMenu: Function;
  onChange?: (value: number | TLanguage | string, id: ESubMenu) => void;
}

const SubMenu: React.FC<ISubMenuProps> = ({ data, onChange, closeSubMenu }) => {
  const [selectedItem, setSelectedItem] = useState<number | TLanguage | string>(data.initialValue);
  const detectMobile = useMemo(() => new MobileDetect(window.navigator.userAgent), [])
  const submenuOpen = data ? 'submenu--open' : '';
  const isMobile = detectMobile.mobile() && !detectMobile.tablet();


  const changeData = useCallback((currentSelectedItem: number | TLanguage | string) => {
    !!onChange && onChange(currentSelectedItem, data.id);
  }, [data, onChange]);

  useEffect(() => changeData(selectedItem), [selectedItem, changeData]);

  return (
    <div className={`submenu ${submenuOpen} ${isMobile ? 'submenu--mobile' : ''}`}>
      <div className="submenu__header" onClick={() => closeSubMenu()}>
        <ArrowLeftIcon />
        <span>{data?.title}</span>
      </div>

      <div className={`submenu__list ${isMobile ? 'submenu__list--mobile' : ''}`}>
        {data.list.map((item: ISubMenuItem, index: number) => (
          <SubMenuItem
            key={index}
            className="item-menu option"
            name={item.title}
            data={item.data}
            checked={item.data === selectedItem}
            onChange={() => setSelectedItem(item.data)}
          />
        ))}
      </div>
    </div>
  )
}

export default SubMenu;

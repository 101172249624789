import './style.css';

import React, { memo } from 'react';
import { useObservable } from 'react-use-observable';
import { interval, map, startWith, switchMap, take } from 'rxjs';

import { Actions, controlsService } from '../../../../../../../services/controls';
import SkipLeft from '../../../../../../icons/SkipLeft';
import SkipRight from '../../../../../../icons/SkipRight';

interface IFastIndicatorProps {
  side: 'left' | 'right';
  actionToShow: Actions;
}

const SkipIndicator: React.FC<IFastIndicatorProps> = ({ actionToShow, side }) => {
  const [show] = useObservable(() => controlsService.on(actionToShow).pipe(
    switchMap(() => interval(500).pipe(
      map(() => false),
      startWith(true),
      take(2)
    ))
  ), [actionToShow])

  return (
    <div className={`fastComp ${show ? 'showAction' : ''}`}
      style={{
        backgroundImage: `linear-gradient(to ${side}, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))`,
        [side]: 0
      }}>
      {side === 'left' ? <SkipLeft /> : <SkipRight />}
    </div>
  );
}

export default memo(SkipIndicator);

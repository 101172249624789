import './App.css';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import TestView from './Pages/TestView';
import Watch from './Pages/Watch';
import WhiteNoise from './Pages/Watch/WhiteNoise';

function App() {
  const guidRegex = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={WhiteNoise} />
        <Route path="/test" exact component={TestView} />
        <Route path="/direct/:playerToken" exact component={Watch} />
        <Route path={`/:tokenKey(${guidRegex})`} component={Watch} />
        <Route path="/:playerToken" component={Watch} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;

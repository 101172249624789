import { BehaviorSubject, of } from 'rxjs';
import { filter, map, pluck, switchMap, tap } from 'rxjs/operators';

import { Events, eventsService } from './events';

class PlayPauseService {
  private isPlaying$ = new BehaviorSubject<boolean>(false);

  getIsPlayingValue() {
    return this.isPlaying$.value;
  }

  listenIsPlaying() {
    return eventsService
      .listenEvents()
      .pipe(
        pluck('name'),
        filter(filteringItem => filteringItem === Events.PLAYER_PAUSED || filteringItem === Events.PLAYER_PLAYED),
        switchMap(lastItem => lastItem === Events.PLAYER_PAUSED ? of(false) : of(true)),
        tap((isPlaying) => this.isPlaying$.next(isPlaying))
      )
  }

  listenPlayerState() {
    return eventsService
      .listenEvents()
      .pipe(
        pluck('name'),
        filter(eventName => [Events.PLAYER_PAUSED, Events.PLAYER_PLAYED, Events.PLAYER_ENDED].includes(eventName as Events)),
        map(validEvent => {
          if (validEvent === Events.PLAYER_PLAYED) return true;
          if (validEvent === Events.PLAYER_PAUSED) return false;
          return undefined;
        })
      )
  }
}

export const playPauseService = new PlayPauseService();

import { useEffect } from 'react';
import { fromEvent } from 'rxjs';

import { Actions, controlsService } from '../services/controls';
import { seekTimeService } from '../services/seekTime';

export const useFastShortcut = () => {
  useEffect(() => {
    const fastShortcutSubscription = fromEvent<KeyboardEvent>(document, 'keydown').subscribe(({ key }) => {
      if (key === 'ArrowLeft') {
        controlsService.registerAction({ name: Actions.REQUEST_SKIP_BACKWARD, data: seekTimeService.getSeekTime() - 10 })
      }
      if (key === 'ArrowRight') {
        controlsService.registerAction({ name: Actions.REQUEST_SKIP_FORWARD, data: seekTimeService.getSeekTime() + 10 })
      }
    })
    return () => fastShortcutSubscription.unsubscribe()
  }, [])
}

import { useEffect, useRef, useState } from 'react';

interface IRemoteControlIndicatorProps {
  thumbnail: string;
}

const RemoteControlIndicator: React.VFC<IRemoteControlIndicatorProps> = ({ thumbnail }) => {
  const thumbnailRef = useRef<HTMLImageElement>(null);
  const [thumbnailAvailable, setThumbnailAvailable] = useState(true);

  useEffect(() => {
    if (thumbnailRef.current) {
      thumbnailRef.current.addEventListener('error', () => setThumbnailAvailable(false));
    }
  }, []);

  return (
    <>
      {thumbnailAvailable ?
        <img ref={thumbnailRef} width="100%" src={thumbnail} alt="remote control active indicator" />
        :
        <div className="nothumbnail"></div>}
    </>)
}

export default RemoteControlIndicator;

import { MutableRefObject, useCallback, useEffect } from 'react';
import { fromEvent } from 'rxjs';

import { ISubtitle } from '../../videozz-common/dtos/player/ISubtitle';
import { ELanguages, TLanguage } from '../interfaces/watch';
import { Actions, controlsService } from '../services/controls';
import { useLocalStorage } from './localStorage';
import { useSubtitles } from './subtitles';

export const useTrack = (videoElementRef: MutableRefObject<HTMLVideoElement | null>) => {
  const subtitles = useSubtitles();
  const localStorageInstance = useLocalStorage();

  const initStoragedSubtitle = useCallback(() => {
    const storagedSubtitleLanguage = localStorageInstance?.getItem('subtitleLanguage') as (TLanguage | null);

    if (storagedSubtitleLanguage) {
      controlsService.registerAction({ name: Actions.CLICK_SUBTITLE_CHANGE, data: storagedSubtitleLanguage })
    }
  }, [localStorageInstance]);

  useEffect(() => {
    const videoElement = videoElementRef.current;
    if (!videoElement) return;

    const tracks = videoElement.textTracks;
    if (tracks.length > 0) return;

    const trackSubscription = fromEvent(videoElement, 'loadedmetadata').subscribe(() => {
      videoElement.crossOrigin = 'anonymous';


      subtitles?.forEach(({ language, url, autogenerated }: ISubtitle) => {
        const trackElement: HTMLTrackElement = document.createElement('track');
        const languageCode = autogenerated ? 'au-To' : language;

        trackElement.kind = 'subtitles';
        trackElement.label = ELanguages[language as TLanguage];
        trackElement.srclang = languageCode;
        trackElement.src = url || '';

        videoElement.appendChild(trackElement);
        initStoragedSubtitle();
      })
    });

    return () => trackSubscription.unsubscribe();
  }, [videoElementRef, subtitles, localStorageInstance, initStoragedSubtitle])
}

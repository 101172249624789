import React, { CSSProperties, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useObservable } from 'react-use-observable';

import { ENV } from '../../../config';
import { testService, TListType } from '../../../services/test';

const containerStyle = {
  width: '100vw',
  height: '100%',
  overflow: 'auto',
  display: 'flex',
  boxSizing: 'border-box',
  padding: '0 16px'
};

const pathListStyle: CSSProperties = {
  flex: 1,
  listStyleType: 'none',
  color: '#DEDEDE',
  padding: 12,
  margin: 0,
  paddingInlineStart: 0,
  boxSizing: 'border-box'
};

const listItemStyle: CSSProperties = {
  width: '100%',
  border: '1px solid #999',
  borderRadius: 8,
  marginBottom: 12,
  padding: 12,
  boxSizing: 'border-box',
};

const listItemTitleStyle: CSSProperties = {
  width: '100%',
  fontSize: 16,
  margin: 0,

};

const actionsStyle: CSSProperties = {
  width: '100%',
  marginTop: 6,
  display: 'flex',
  justifyContent: 'flex-end'
};

const buttonGoStyle: CSSProperties = {
  border: 'none',
  background: '#FEC930',
  color: '#000',
  borderRadius: 4,
  marginLeft: 12,
  cursor: 'pointer',
  padding: '4px 6px',
  fontSize: 12,
  boxSizing: 'border-box',
}

const TestView: React.FC = React.memo(() => {
  const [watchList] = useObservable(() => testService.getList('tokens'), []);
  const [directsList] = useObservable(() => testService.getList('directs'), []);
  const [embedsList] = useObservable(() => testService.getList('embeds'), []);

  const history = useHistory();

  const goToPath = (type: TListType, id: number, preURL = '') => {
    testService.getPlayerToken(type, id).subscribe(playerToken => {
      history.push(`${preURL}/${playerToken}`);
    })
  }

  useEffect(() => {
    if (ENV === 'prod') {
      history.push('/');
    }

    const loadingElement = document.querySelector('#loading');
    if (loadingElement) {
      loadingElement.classList.add('fadeOut');
    }
  }, [history]);

  return (
    <div style={containerStyle as CSSProperties}>
      <ul style={pathListStyle}>
        <h4 style={{ color: '#dedede' }}>Watch list</h4>
        {watchList && watchList.map((item, index) => (
          <li key={index} style={listItemStyle}>
            <h2 style={listItemTitleStyle}>{item.title}</h2>
            <div style={actionsStyle}>
              {item.id !== undefined &&
                <button
                  style={buttonGoStyle}
                  onClick={() => goToPath('tokens', item.id as number)}
                >
                  Ir para teste
                </button>
              }
            </div>
          </li>
        ))
        }
      </ul>
      <ul style={pathListStyle}>
        <h4 style={{ color: '#dedede' }}>Directs list</h4>
        {directsList && directsList.map((item, index) => (
          <li key={index} style={listItemStyle}>
            <h2 style={listItemTitleStyle}>{item.title}</h2>
            <div style={actionsStyle}>
              {item.id !== undefined &&
                <button
                  style={buttonGoStyle}
                  onClick={() => goToPath('directs', item.id as number, '/direct')}
                >
                  Ir para teste
                </button>
              }
            </div>
          </li>
        ))
        }
      </ul>
      <ul style={pathListStyle}>
        <h4 style={{ color: '#dedede' }}>Embeds list</h4>
        {embedsList && embedsList.map((item, index) => (
          <li key={index} style={listItemStyle}>
            <h2 style={listItemTitleStyle}>{item.title}</h2>
            <div style={actionsStyle}>
              {item.tokenKey !== undefined &&
                <button
                  style={buttonGoStyle}
                  onClick={() => history.push(`/${item.tokenKey}`)}
                >
                  Ir para teste
                </button>
              }
            </div>
          </li>
        ))
        }
      </ul>
    </div>
  );
});

export default TestView;

import MenuProvider from '../../../../../contexts/PlayerContext';
import Controls from '../Controls';
import ControlsLite from '../ControlsLite';

interface IControlsOverlyProps {
  isPreviewMode?: boolean
}

const ControlsOverlay: React.FC<IControlsOverlyProps> = ({ isPreviewMode }) => {
  if (isPreviewMode) {
    return <ControlsLite />
  }
  return <MenuProvider><Controls /></MenuProvider>
}

export default ControlsOverlay;

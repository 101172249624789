import { useMemo } from 'react';
import { useObservable } from 'react-use-observable';

import { Actions, controlsService } from '../../../../../../services/controls';
import { playPauseService } from '../../../../../../services/playPause';
import Button from '../Button';
import Icon from './Icon';

interface IPlayProps {
  size?: 'large' | 'medium';
}

const Play: React.FC<IPlayProps> = ({ size }) => {
  const isSizeLarge = useMemo(() => size === 'large', [size]);
  const [isPlaying] = useObservable(() => playPauseService.listenPlayerState(), []);

  const registerAction = () => {
    controlsService.registerAction({ name: isPlaying ? Actions.CLICK_PAUSE : Actions.CLICK_PLAY });
  }

  return (
    <Button className={isSizeLarge ? 'playerButton--large' : ''} onClick={() => registerAction()}>
      <Icon isPlaying={isPlaying} />
    </Button>
  );
}

export default Play;

import React from 'react';

interface IProps {
  width?: string | number;
  height?: string | number;
  fill?: string;
}

const ErrorIcon: React.FC<IProps> = (props: IProps) => {
  return (
    <svg
      version="1.1"
      x="0px" y="0px"
      width={props.width}
      height={props.height}
      viewBox="0 0 19.1 17"
      fill={props.fill}
    >
      <path
        d="M2,17h15.1c1.5,0,2.5-1.7,1.7-3L11.3,1c-0.8-1.3-2.7-1.3-3.5,0L0.3,14C-0.5,15.3,0.5,17,2,17z M9.5,10c-0.6,0-1-0.4-1-1V7
	c0-0.6,0.4-1,1-1s1,0.4,1,1v2C10.5,9.6,10.1,10,9.5,10z M9.5,14L9.5,14c-0.6,0-1-0.4-1-1v0c0-0.6,0.4-1,1-1h0c0.6,0,1,0.4,1,1v0
	C10.5,13.6,10.1,14,9.5,14z"
      />
    </svg>
  );
}

export default ErrorIcon;
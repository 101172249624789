export const useLocalStorage = () => {
  const checkStorageAvaliability = () => {
    try {
      return Boolean(window.localStorage);
    } catch {
      return false;
    }
  }

  if (checkStorageAvaliability()) {
    return window.localStorage;
  }

  return null;
}

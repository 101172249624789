import './style.css';

import React, { memo } from 'react';

import { TLanguage } from '../../../../../../../../interfaces/watch';

interface SubMenuItemProps {
  data: number | TLanguage | string;
  name: string;
  checked: boolean;
  className?: string;
  onChange?: () => void;
}

const SubMenuItem: React.FC<SubMenuItemProps> = ({ data, name, checked, className, onChange }) => {
  return (
    <label className={className}>
      <input type='radio' onChange={onChange} value={data} checked={checked} />
      <span>{name}</span>
    </label>
  );
};

export default memo(SubMenuItem);

import React, { useCallback, useRef } from 'react';
import { useObservable } from 'react-use-observable';

import { Actions, controlsService } from '../../../../../../services/controls';
import { pipService } from '../../../../../../services/pip';
import ExitPiPIcon from '../../../../../icons/ExitPiP';
import RequestPiPIcon from '../../../../../icons/RequestPiP';
import Button from '../Button';

const PiP: React.FC = () => {
  const [isPiP] = useObservable(() => pipService.listenIsPiPEnabled(), []);
  const isPiPSupported = useRef(!!document.pictureInPictureEnabled);

  const registerAction = useCallback((PiPActivated: boolean) => {
    controlsService.registerAction({ name: PiPActivated ? Actions.CLICK_EXIT_PIP : Actions.CLICK_REQUEST_PIP });
  }, []);

  return (
    <>
      {isPiPSupported.current &&
        <Button onClick={() => registerAction(!!isPiP)}>
          {isPiP ? <ExitPiPIcon /> : <RequestPiPIcon />}
        </Button>
      }
    </>
  )
}

export default PiP;

import { map, pluck, take } from 'rxjs';

import { Events, eventsService } from './events';

class SeekService {
  getVideoCurrentTime() {
    return eventsService.on<number>(Events.PLAYER_TIMEUPDATE).pipe(pluck('event'));
  }

  getVideoDuration() {
    return eventsService.on<number>(Events.PLAYER_DURATIONCHANGE)
      .pipe(
        take(1),
        pluck('event'),
        map(duration => duration || 0)
      )
  }

  getBufferedTime() {
    return eventsService.on<number>(Events.PLAYER_BUFFERED_TIME).pipe(pluck('event'));
  }
}

export const seekService = new SeekService();


import { memo } from 'react';

interface IPlayIconProps {
  className?: string;
}

const PlayIcon: React.FC<IPlayIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path
        fill="currentColor"
        d="M2.7,2.5v18.9c0,1.4,1.6,2.4,2.8,1.5l14.9-9.5c1.1-0.8,1.1-2.4,0-3.1L5.5,1C4.2,0.2,2.7,1.1,2.7,2.5z" />
    </svg>
  )
}

export default memo(PlayIcon);


import './style.css';

import MobileDetect from 'mobile-detect';
import React, { useCallback, useMemo, useState } from 'react';
import ReactSlider from 'react-slider';
import { useObservable } from 'react-use-observable';

import { Actions, controlsService } from '../../../../../../services/controls';
import { volumeService } from '../../../../../../services/volume';
import VolumeDownIcon from '../../../../../icons/VolumeDown';
import VolumeOffIcon from '../../../../../icons/VolumeOff';
import VolumeUpIcon from '../../../../../icons/VolumeUp';
import Button from '../Button';

const VolumeControl: React.FC = () => {
  const mobileDetect = useMemo(() => new MobileDetect(window.navigator.userAgent), []);
  const [volume] = useObservable(() => volumeService.listenVolume(), []);
  const [isMuted] = useObservable(() => volumeService.listenIsMuted(), []);
  const [before, setBefore] = useState(100);
  const [classHover, setClassHover] = useState(false);
  const [thumbClick, setThumbClick] = useState(false);

  const registerAction = useCallback(() => {
    controlsService.registerAction({ name: Actions.CLICK_MUTED, data: !isMuted });
  }, [isMuted]);

  const handleChange = useCallback((value: number) => {
    if (isMuted) controlsService.registerAction({ name: Actions.CLICK_MUTED, data: false });
    controlsService.registerAction({ name: Actions.CLICK_VOLUME_CHANGE, data: value });
  }, [isMuted]);

  const handleBeforeChange = (value: number) => {
    if (value !== 0) setBefore(value);
    setThumbClick(true);
  }

  const handleAfterChange = (value: number) => {
    if (value === 0) {
      controlsService.registerAction({ name: Actions.CLICK_VOLUME_CHANGE, data: before });
      controlsService.registerAction({ name: Actions.CLICK_MUTED, data: true });
    }
    setThumbClick(false);
  }

  const handleIconChange = () => {
    if (isMuted || volume! === 0) {
      return <VolumeOffIcon />
    }

    if (volume! > 50) {
      return <VolumeUpIcon />
    }

    if (volume! > 0) {
      return <VolumeDownIcon />
    }

    return <VolumeUpIcon />
  }

  return (
    <div
      onMouseOver={() => setClassHover(true)}
      onMouseOut={() => setClassHover(false)}
      style={{ paddingRight: classHover || thumbClick ? '0.5rem' : '' }}
    >
      <Button onClick={() => registerAction()}>
        {handleIconChange()}
      </Button>

      {!mobileDetect.mobile() &&
        <ReactSlider
          className={`volume ${classHover || thumbClick ? "volume--show" : ""}`}
          thumbClassName="volume__thumb"
          trackClassName="volume__track"
          defaultValue={100}
          value={isMuted ? 0 : volume!}
          onAfterChange={(value) => handleAfterChange(value)}
          onBeforeChange={(value) => handleBeforeChange(value)}
          onChange={(value) => handleChange(value)}
          renderThumb={(props) => <div {...props} onMouseDown={() => setThumbClick(true)} onMouseUp={() => setThumbClick(false)}></div>}
        />
      }
    </div>
  )
}

export default VolumeControl;

import Hls from 'hls.js';
import { useEffect } from 'react';
import { fromEvent } from 'rxjs';

import { Events, eventsService } from '../services/events';

export const useBufferTime = (hls: Hls) => {
  useEffect(() => {
    if (Hls.isSupported()) {
      const bufferedSubscription = fromEvent(hls, Hls.Events.FRAG_BUFFERED).subscribe((data) => {
        eventsService.registerEvent({
          name: Events.PLAYER_BUFFERED_TIME,
          event: (data[1] as any).frag.endDTS
        })
      })

      return () => bufferedSubscription.unsubscribe();
    }
  }, [hls]);
}

import '../../../../../assets/transitions/fade.css';
import './style.css';

import MobileDetect from 'mobile-detect';
import React, { useEffect, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useObservable } from 'react-use-observable';
import { take } from 'rxjs/operators';

import { useControlStorage } from '../../../../../hooks/controlStorage';
import { useShortcuts } from '../../../../../hooks/shortcuts';
import { useVideoClick } from '../../../../../hooks/videoClick';
import { activeService } from '../../../../../services/active';
import { Events, eventsService } from '../../../../../services/events';
import { remoteControlService } from '../../../../../services/remoteControl';
import Backdrop from './Backdrop';
import BigPlay from './BigPlay';
import Chromecast from './Chromecast';
import Duration from './Duration';
import Fullscreen from './Fullscreen';
import Indicator from './Indicator';
import Menu from './Menu';
import PiP from './PiP';
import Play from './Play';
import SafeVideoLogo from './SafeLogo';
import SeekSlider from './SeekSlider';
import Settings from './Settings';
import VolumeControl from './Volume';

const Controls: React.FC = () => {
  const mobileDetect = useMemo(() => new MobileDetect(window.navigator.userAgent), []);
  const transitionNodeRef = React.useRef<HTMLElement>(null);
  const [active] = useObservable(() => activeService.listenIsActive(), []);
  const [remoteControlActive] = useObservable(() => remoteControlService.getRemoteControlMode(), []);

  useControlStorage();
  useShortcuts();
  useVideoClick(transitionNodeRef);

  useEffect(() => {
    eventsService.on(Events.PLAYER_LOADEND).pipe(take(1)).subscribe(() => {
      if (transitionNodeRef.current) {
        transitionNodeRef.current.classList.remove('invisible');
      }
    });
  }, []);

  return (
    <CSSTransition nodeRef={transitionNodeRef} in={!!active} timeout={500} classNames="fade-transition">
      <section ref={transitionNodeRef} className="controls invisible">
        <header>
          <Backdrop position="top" />
          <div className="controlsContainer">
            <Chromecast />
            {(!remoteControlActive && !!mobileDetect.mobile()) && <Settings />}
          </div>
        </header>

        <BigPlay />
        <Indicator />

        <footer>
          <Backdrop position="bottom" />
          <div className="controlsContainer">
            <SeekSlider />
            <section>
              <div className="mediaControls">
                <Play />
                <VolumeControl />
                <Duration />
              </div>
              <div>
                {!remoteControlActive && <PiP />}
                {!remoteControlActive && !mobileDetect.mobile() && <Settings />}
                <SafeVideoLogo />
                <Fullscreen />
              </div>
            </section>
          </div>
        </footer>

        <Menu />
      </section>
    </CSSTransition>
  );
};

export default Controls;

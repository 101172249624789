const ArrowRightIcon = () => {
  return (
    <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24">
      <path fill='currentColor'
        d="M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31c.39-.39.39-1.02 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z">
      </path>
    </svg>
  )
}

export default ArrowRightIcon;

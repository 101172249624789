import React, { memo } from 'react';

import { ISubMenuItem } from '../../../../../../../interfaces/menu';
import { TLanguage } from '../../../../../../../interfaces/watch';
import ArrowRightIcon from '../../../../../../icons/ArrowRight';

interface IMenuItemProps {
  icon: JSX.Element;
  name: string | TLanguage;
  subItems: ISubMenuItem[];
  currentValue: string | TLanguage;
  setSubItem: (list: ISubMenuItem[]) => void;
}

const MenuItem: React.FC<IMenuItemProps> = ({ icon, name, subItems, currentValue, setSubItem }) => {
  return (
    <div className={`item-menu`} onClick={() => setSubItem(subItems)}>
      <div>{icon}<span>{name}</span></div>
      <div><span>{currentValue}</span><ArrowRightIcon /></div>
    </div>
  )
}

export default memo(MenuItem);

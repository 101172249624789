import { useEffect } from 'react';
import { filter, fromEvent, pluck } from 'rxjs';

import { Events, eventsService } from '../../services/events';

export const useChromecastConnection = () => {
  useEffect(() => {
    const windowCast = (window as any).cast;

    if (windowCast) {
      const castContext = windowCast.framework.CastContext.getInstance();

      if (!(window as any).chrome.cast) return;

      castContext.setOptions({
        receiverApplicationId: (window as any).chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
        autoJoinPolicy: (window as any).chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED
      });

      const castSessionStateChanged$ = fromEvent<{ sessionState: string }>(castContext, windowCast.framework.CastContextEventType.SESSION_STATE_CHANGED).pipe(pluck('sessionState'));

      const connectedSubscription = castSessionStateChanged$
        .pipe(filter(sessionState => [windowCast.framework.SessionState.SESSION_STARTED, windowCast.framework.SessionState.SESSION_RESUMED].includes(sessionState)))
        .subscribe(() => eventsService.registerEvent({ name: Events.CHROMECAST_CONNECTED }))

      const disconnectedSubscription = castSessionStateChanged$
        .pipe(filter(sessionState => sessionState === windowCast.framework.SessionState.SESSION_ENDED))
        .subscribe(() => eventsService.registerEvent({ name: Events.CHROMECAST_DISCONNECTED }));

      return () => {
        connectedSubscription.unsubscribe();
        disconnectedSubscription.unsubscribe();
      };
    }
  }, []);
}

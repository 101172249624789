import Hls from 'hls.js';
import { useEffect } from 'react';

import { Events, eventsService } from '../services/events';
import { seekTimeService } from '../services/seekTime';

export const useBufferControl = (hls: Hls) => {
  useEffect(() => {
    const castConnectionSubscription = eventsService
      .on(Events.CHROMECAST_CONNECTED)
      .subscribe(() => hls.stopLoad())

    return () => castConnectionSubscription.unsubscribe();
  }, [hls]);

  useEffect(() => {
    const castDisconnectionSubscription = eventsService
      .on(Events.CHROMECAST_DISCONNECTED)
      .subscribe(() => hls.startLoad(seekTimeService.getSeekTime()))

    return () => castDisconnectionSubscription.unsubscribe();
  }, [hls]);
}

import PlayerService from '../services/player';
import { useFastActions } from './fastActions';
import { useFullscreenActions } from './fullscreen';
import { usePiPActions } from './pip';
import { usePlaybackRateActions } from './playbackRate';
import { usePlayPauseActions } from './playPause';
import { useSeekAction } from './seek';
import { useVolumeActions } from './volume';

export const useVideoActions = (playerService: React.MutableRefObject<PlayerService>, videoElementRef: React.MutableRefObject<HTMLVideoElement | null>) => {
  usePlayPauseActions(playerService);
  useFullscreenActions();
  useSeekAction(playerService);
  usePiPActions(videoElementRef);
  useVolumeActions(playerService, videoElementRef);
  usePlaybackRateActions(videoElementRef);
  useFastActions(playerService);
}

export type TTokenType = 'embed' | 'direct' | 'watch';

export type TLanguage = 'pt-BR' | 'en-US' | 'es-ES';

export interface ISubtitle {
  key: string;
  url?: string;
  accountId: string;
  name: string;
  videoKey: string;
  language: TLanguage;
  autogenerated: boolean;
  enabled: boolean;
  updatedAt?: Date;
  createdAt?: Date;
  deletedAt?: Date;
}

export interface IWatch {
  sessionKey: string;
  sessionToken: string;
  thumbnail: string;
  playlist: string;
  seekTime: number;
  subtitles: ISubtitle[]
  title: string;
  partner: string;
  videoKey: string;
  tokenKey: string;
}

export enum ELanguages {
  'pt-BR' = 'Português',
  'en-US' = 'Inglês',
  'es-ES' = 'Espanhol',
  'au-To' = 'Automática'
}

import { useEffect } from 'react';
import { pluck } from 'rxjs';

import { IVolumeChangeEventData } from '../interfaces/volumechange';
import { Actions, controlsService } from '../services/controls';
import { Events, eventsService } from '../services/events';
import { useLocalStorage } from './localStorage';

export const useStorageVolume = () => {
  const localStorageInstance = useLocalStorage();

  useEffect(() => {
    if (localStorageInstance) {
      const volumeSubscription = eventsService.on<IVolumeChangeEventData>(Events.PLAYER_VOLUME_CHANGED).pipe(
        pluck('event'),
      ).subscribe((data) => localStorageInstance.setItem('volume', `${JSON.stringify(data)}`))

      return () => volumeSubscription.unsubscribe();
    }
  }, [localStorageInstance])

  useEffect(() => {
    if (localStorageInstance) {
      try {
        const { volume, muted }: IVolumeChangeEventData = JSON.parse(localStorageInstance.getItem('volume')!) || { volume: 1, muted: false };
        controlsService.registerAction({
          name: Actions.CLICK_VOLUME_CHANGE,
          data: volume * 100
        })
        controlsService.registerAction({
          name: Actions.CLICK_MUTED,
          data: muted
        })
      }
      catch (error) {
        console.error(error)
      }
    }
  }, [localStorageInstance])
}

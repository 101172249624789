import './style.css';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

interface Time {
  hh: string;
  mm: string;
  ss: string;
}

export interface Props {
  max: number;
  currentTime: number;
  progress?: number;
  onChange: (time: number, offsetTime: number) => void;
  hideHoverTime?: boolean;
  offset?: number;
  secondsPrefix?: string;
  minutesPrefix?: string;
  limitTimeTooltipBySides?: boolean;
}

function secondsToTime(seconds: number, offset: number): Time {
  const roundedSeconds = Math.round(seconds + offset);

  const hours: number = Math.floor(roundedSeconds / 3600);
  const divirsForMinutes: number = roundedSeconds % 3600;
  const minutes: number = Math.floor(divirsForMinutes / 60);
  const sec: number = Math.ceil(divirsForMinutes % 60);

  return {
    hh: hours.toString(),
    mm: minutes < 10 ? `0${minutes}` : minutes.toString(),
    ss: sec < 10 ? `0${sec}` : sec.toString(),
  };
}

export const ProgressSlider: React.FC<Props> = ({
  max = 100,
  currentTime = 0,
  progress = 0,
  hideHoverTime = false,
  offset = 0,
  secondsPrefix = '',
  minutesPrefix = '',
  onChange = () => undefined,
  limitTimeTooltipBySides = false,
}) => {
  const [seekHoverPosition, setSeekHoverPosition] = useState(0);

  const seeking = useRef(false);
  const trackWidth = useRef(0);
  const mobileSeeking = useRef(false);
  const track = useRef<HTMLDivElement>(null);
  const hoverTime = useRef<HTMLDivElement>(null);

  const hoverTimeValue = useMemo(() => {
    const percent: number = (seekHoverPosition * 100) / trackWidth.current;
    const time: number = Math.floor(+(percent * (max / 100)));
    const times: Time = secondsToTime(time, offset);

    if (max + offset < 60) {
      return secondsPrefix + times.ss;
    }

    if (max + offset < 3600) {
      return `${minutesPrefix + times.mm}:${times.ss}`;
    }

    return `${times.hh}:${times.mm}:${times.ss}`;
  }, [max, minutesPrefix, offset, secondsPrefix, seekHoverPosition]);

  const changeCurrentTimePosition = useCallback((pageX: number) => {
    const left = track.current?.getBoundingClientRect().left || 0;
    let position = pageX - left;

    position = position < 0 ? 0 : position;
    position = position > trackWidth.current ? trackWidth.current : position;

    setSeekHoverPosition(position);

    const percent = (position * 100) / trackWidth.current;
    const time = +(percent * (max / 100));

    onChange(time, time + offset);
  }, [onChange, offset, max])

  const handleTouchSeeking = useCallback((event: TouchEvent) => {
    event.preventDefault();
    event.stopPropagation();

    let pageX = 0;

    for (let i = 0; i < event.changedTouches.length; i++) {
      pageX = event.changedTouches?.[i].pageX;
    }

    pageX = pageX < 0 ? 0 : pageX;

    if (mobileSeeking.current) {
      changeCurrentTimePosition(pageX);
    }
  }, [changeCurrentTimePosition])

  const handleSeeking = useCallback((event: MouseEvent) => {
    if (seeking.current) {
      changeCurrentTimePosition(event.pageX);
    }
  }, [changeCurrentTimePosition]);

  const setTrackWidthState = useCallback(() => {
    if (track.current) {
      trackWidth.current = track.current.offsetWidth;
    }
  }, []);

  function handleTrackHover(
    clear: boolean,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void {
    const left = track.current?.getBoundingClientRect().left || 0;
    const position = clear ? 0 : event.pageX - left;

    setSeekHoverPosition(position);
  }

  function getPositionStyle(time: number): { width: string } {
    const divider = max || -1; // prevent division by zero
    const position = (time * 100) / divider;
    return { width: `${position}%` };
  }

  function getBufferingStyle(buffered: number): { transform: string } {
    const divider = max || -1; // prevent division by zero
    const position = (buffered * 100) / divider;
    return { transform: `scaleX(${position / 100})` };
  }

  function getSeekHoverPosition(): { transform: string } {
    const position = (seekHoverPosition * 100) / trackWidth.current;
    return { transform: `scaleX(${position / 100})` };
  }

  function getHoverTimePosition(): { transform: string } {
    let position = 0;

    if (hoverTime.current) {
      position = seekHoverPosition - hoverTime.current.offsetWidth / 2;

      if (limitTimeTooltipBySides) {
        if (position < 0) {
          position = 0;
        } else if (
          position + hoverTime.current.offsetWidth >
          trackWidth.current
        ) {
          position = trackWidth.current - hoverTime.current.offsetWidth;
        }
      }
    }

    return { transform: `translateX(${position}px)` };
  }

  const setMobileSeeking = useCallback((state = true) => {
    mobileSeeking.current = state;
    setSeekHoverPosition(state ? seekHoverPosition : 0);
  }, [seekHoverPosition, setSeekHoverPosition]);

  const setSeeking = useCallback((state: boolean, event: MouseEvent) => {
    event.preventDefault();

    handleSeeking(event);
    seeking.current = state;

    setSeekHoverPosition(state ? seekHoverPosition : 0);
  }, [seekHoverPosition, handleSeeking, setSeekHoverPosition]);

  const mouseSeekingHandler = useCallback((event: MouseEvent) => {
    setSeeking(false, event);
  }, [setSeeking]);

  const mobileTouchSeekingHandler = useCallback(() => {
    setMobileSeeking(false);
  }, [setMobileSeeking]);

  function isThumbActive(): boolean {
    return seekHoverPosition > 0 || seeking.current;
  }

  useEffect(() => {
    setTrackWidthState();
    window.addEventListener('resize', setTrackWidthState);
    return () => window.removeEventListener('resize', setTrackWidthState);
  }, [max, offset, setTrackWidthState]);

  useEffect(() => {
    window.addEventListener('mousemove', handleSeeking);
    return () => window.removeEventListener('mousemove', handleSeeking);
  }, [max, offset, handleSeeking]);

  useEffect(() => {
    window.addEventListener('mouseup', mouseSeekingHandler);
    return () => window.removeEventListener('mouseup', mouseSeekingHandler);
  }, [max, offset, mouseSeekingHandler]);

  useEffect(() => {
    window.addEventListener('touchmove', handleTouchSeeking);
    return () => window.removeEventListener('touchmove', handleTouchSeeking);
  }, [max, offset, handleTouchSeeking]);

  useEffect(() => {
    window.addEventListener('touchend', mobileTouchSeekingHandler);
    return () => window.removeEventListener('touchend', mobileTouchSeekingHandler);
  }, [max, offset, mobileTouchSeekingHandler]);

  return (
    <div className="ui-video-seek-slider">
      <div
        className={isThumbActive() ? 'track active' : 'track'}
        ref={track}
        onMouseMove={(event) => handleTrackHover(false, event)}
        onMouseLeave={(event) => handleTrackHover(true, event)}
        onMouseDown={(event) => setSeeking(true, event as any)}
        onTouchStart={() => setMobileSeeking(true)}
      >
        <div className="main">
          <div
            className="buffered"
            style={getBufferingStyle(progress)}
          />

          <div
            className="seek-hover"
            style={getSeekHoverPosition()}
          />

          <section className="seek-slider" style={getPositionStyle(currentTime)}>
            <div className="connect" />
            <div className={isThumbActive() ? 'thumb active' : 'thumb'}>
              <div className="handler" />
            </div>
          </section>
        </div>
      </div>

      {!hideHoverTime && (
        <div
          className={isThumbActive() ? 'hover-time active' : 'hover-time'}
          style={getHoverTimePosition()}
          ref={hoverTime}
        >
          {hoverTimeValue}
        </div>
      )}
    </div>
  );
};

import { memo } from 'react';

const ExitPiPIcon = () => {
  return (
    <div>
      <svg
        focusable="false"
        x="0px" y="0px"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M18.5,6.5H5.5c-0.5,0-1.1,0.5-1.1,1.1v8.7c0,0.5,0.4,1.1,1.1,1.1h13.1c0.5,0,1.1-0.5,1.1-1.1V7.6
    C19.6,7.1,19.1,6.5,18.5,6.5z M24,19.6V4.4c0-1.2-1-2.2-2.2-2.2H2.2C1,2.2,0,3.2,0,4.4v15.3c0,1.2,1,2.2,2.2,2.2h19.6
    C23,21.8,24,20.8,24,19.6z M20.7,19.6H3.3c-0.5,0-1.1-0.5-1.1-1.1V5.5c0-0.7,0.5-1.1,1.1-1.1h17.5c0.5,0,1.1,0.4,1.1,1.1v13.1
    C21.8,19.2,21.3,19.6,20.7,19.6z"
        />
      </svg>
    </div>
  )
}

export default memo(ExitPiPIcon);

import MobileDetect from "mobile-detect";
import { useCallback, useEffect } from "react";
import { fromEvent, of } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";

import { activeService } from "../services/active";
import { Actions, controlsService } from "../services/controls";
import { Events, eventsService } from "../services/events";
import { fullscreenService } from "../services/fullscreen";

export const useFullscreenActions = () => {
  const exitFullscreen = useCallback(() => {
    const browserPrefix = fullscreenService.getBrowserPrefix();
    const isFullscreenActivated = !!fullscreenService.getFullscreenElement();

    if (isFullscreenActivated) {
      if (browserPrefix && browserPrefix !== "moz")
        (document as any)[browserPrefix + "ExitFullscreen"]();
      else document.exitFullscreen();
    }
  }, []);

  const requestFullscreen = useCallback(() => {
    const isFullscreenSupported = fullscreenService.getIsFullscreenSupported();
    const browserPrefix = fullscreenService.getBrowserPrefix();
    const isFullscreenEnabled = fullscreenService.getFullscreenEnabled();
    const isFullscreenActivated = !!fullscreenService.getFullscreenElement();
    const playerComponent = document.getElementById("safevideo-player");

    if (
      playerComponent &&
      isFullscreenSupported &&
      !isFullscreenActivated &&
      isFullscreenEnabled
    ) {
      if (browserPrefix && browserPrefix !== "moz")
        (playerComponent as any)[browserPrefix + "RequestFullscreen"]();
      else playerComponent.requestFullscreen();
    }
  }, []);

  useEffect(() => {
    const requestSubscription = controlsService
      .on(Actions.CLICK_REQUEST_FULLSCREEN)
      .subscribe(() => {
        requestFullscreen();
      });

    return () => requestSubscription.unsubscribe();
  }, [requestFullscreen]);

  useEffect(() => {
    const exitSubscription = controlsService
      .on(Actions.CLICK_EXIT_FULLSCREEN)
      .subscribe(() => {
        exitFullscreen();
      });

    return () => exitSubscription.unsubscribe();
  }, [exitFullscreen]);

  useEffect(() => {
    const mobileDetect = new MobileDetect(window.navigator.userAgent);
    const activeVideoClicksSubscription = activeService
      .listenActiveVideoClicks(2)
      .pipe(
        map(() => !!fullscreenService.getFullscreenElement()),
        filter(() => !mobileDetect.mobile())
      )
      .subscribe((isFullscreenActive) => {
        if (isFullscreenActive) {
          controlsService.registerAction({
            name: Actions.CLICK_EXIT_FULLSCREEN,
          });
        } else {
          controlsService.registerAction({
            name: Actions.CLICK_REQUEST_FULLSCREEN,
          });
        }
      });

    return () => activeVideoClicksSubscription.unsubscribe();
  }, []);
};

export const useFullScreenEvents = () => {
  useEffect(() => {
    const browserPrefix = fullscreenService.getBrowserPrefix();

    const fullscreenChangeSubscription = fromEvent(
      document,
      `${browserPrefix === "moz" ? "" : browserPrefix}fullscreenchange`
    )
      .pipe(
        switchMap((mappedItem) => {
          fullscreenService.defineFullscreenElement();
          return of(mappedItem);
        })
      )
      .subscribe((data) => {
        eventsService.registerEvent({
          name: Events.PLAYER_FULLSCREEN_CHANGED,
          event: data,
        });
      });

    return () => fullscreenChangeSubscription.unsubscribe();
  }, []);
};

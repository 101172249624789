import './style.css';

import { useObservable } from 'react-use-observable';

import { loadingService } from '../../../../../services/loading';

const Loading: React.VFC = () => {
  const [loading] = useObservable(() => loadingService.listenIsLoading(), []);

  return (
    <>
      {loading &&
        <svg
          className="player__loading"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 50 50"
          preserveAspectRatio="xMidYMid"
        >
          <path fill="#FEC930"
            d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
          </path>
        </svg>
      }
    </>
  );
}

export default Loading;

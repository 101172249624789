import { useEffect } from 'react';

import { TLanguage } from '../interfaces/watch';
import { Actions, controlsService } from '../services/controls';
import { useLocalStorage } from './localStorage';

export const useStorageSubtitle = () => {
  const localStorageInstance = useLocalStorage();

  useEffect(() => {
    if (!localStorageInstance) {
      return;
    }

    const subtitleChangeSubscription = controlsService.on<TLanguage>(Actions.CLICK_SUBTITLE_CHANGE).subscribe(({ data: selectedLanguage }) => {
      if (selectedLanguage !== undefined) {
        localStorageInstance.setItem('subtitleLanguage', selectedLanguage);
      }
    });

    return () => subtitleChangeSubscription.unsubscribe();
  }, [localStorageInstance]);
}

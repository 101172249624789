import { Level } from 'hls.js';
import { map, pluck } from 'rxjs';

import { Events, eventsService } from './events';

class LevelsService {
  getLevels() {
    return eventsService.on(Events.PLAYER_MANIFEST_PARSED)
      .pipe(
        pluck('event'),
        pluck('levels'),
        map((data: Level[]) => [
          ...data.map(({ height }, index) => (
            { title: `${height}p`, data: index }
          )).reverse(),
          { title: "Automático", data: -1 }
        ])
      );
  }
}

export const levelsService = new LevelsService();

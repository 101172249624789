import React from 'react';

import { Actions } from '../../../../../../services/controls';
import PlayPauseIndicator from './PlayPauseIndicator';
import SkipIndicator from './SkipIndicator';

const Indicator: React.FC = () => {
  return (
    <>
      <PlayPauseIndicator actionToShow={Actions.CLICK_PAUSE} />
      <PlayPauseIndicator actionToShow={Actions.CLICK_PLAY} />
      <SkipIndicator actionToShow={Actions.REQUEST_SKIP_BACKWARD} side={'left'} />
      <SkipIndicator actionToShow={Actions.REQUEST_SKIP_FORWARD} side={'right'} />
    </>
  )
}

export default Indicator;

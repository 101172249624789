import '../../../../../assets/transitions/fade.css';
import '../Controls/style.css';

import { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useObservable } from 'react-use-observable';
import { take } from 'rxjs';

import { activeService } from '../../../../../services/active';
import { Actions, controlsService } from '../../../../../services/controls';
import { Events, eventsService } from '../../../../../services/events';
import Backdrop from '../Controls/Backdrop';
import Mute from './Mute';


const ControlsLite: React.FC = () => {
  const [active] = useObservable(() => activeService.listenIsActive(), []);
  const transitionNodeRef = useRef<HTMLElement>(null);

  useEffect(() => {
    eventsService.on(Events.PLAYER_LOADEND).pipe(take(1)).subscribe(() => {
      if (transitionNodeRef.current) {
        transitionNodeRef.current.classList.remove('invisible');
      }
    });
  }, []);

  useEffect(() => {
    controlsService.registerAction({ name: Actions.CLICK_MUTED, data: true })
  }, []);

  return (
    <CSSTransition nodeRef={transitionNodeRef} in={!!active} timeout={500} classNames="fade-transition">
      <section ref={transitionNodeRef} className="controls invisible">
        <header>
          <Backdrop position="top" />
          <div className="controlsContainer reverse">
            <Mute />
          </div>
        </header>
      </section>
    </CSSTransition>
  );
}

export default ControlsLite;

import { useEffect, useMemo } from 'react';
import { BehaviorSubject, fromEvent } from 'rxjs';

import { Actions, controlsService } from '../services/controls';
import { fullscreenService } from '../services/fullscreen';

export const useFullscreenShortcut = () => {
  const isFullscreen$ = useMemo(() => new BehaviorSubject(false), []);

  useEffect(() => {
    const fullscreenSubscription = fullscreenService.getIsFullscreen().subscribe((isFullscreen) => isFullscreen$.next(isFullscreen))
    return () => fullscreenSubscription.unsubscribe();
  }, [isFullscreen$])

  useEffect(() => {
    const fullscreenSubscription = fromEvent<KeyboardEvent>(document, 'keydown').subscribe(data => {
      if (data.key.toLocaleLowerCase() === 'f') {
        controlsService.registerAction({ name: isFullscreen$.value ? Actions.CLICK_EXIT_FULLSCREEN : Actions.CLICK_REQUEST_FULLSCREEN });
      }
    })

    return () => fullscreenSubscription.unsubscribe();
  }, [isFullscreen$])
}

import { memo } from 'react';

const SkipLeft = () => {
  return <svg focusable="false" viewBox="0 0 92 32" fill="none">
    <path opacity="0.7" fillRule="evenodd" clipRule="evenodd" d="M57.1273 0.30051C58.6228 -0.553964 60.4912 0.515504 60.4912 2.22603V29.774C60.4912 31.4845 58.6228 32.554 57.1273 31.6995L33.0194 17.9255C31.5225 17.0703 31.5225 14.9297 33.0194 14.0745L57.1273 0.30051Z" fill="#B0BEC5" />
    <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M87.6761 0.30051C89.1716 -0.553964 91.04 0.515504 91.04 2.22603V29.774C91.04 31.4845 89.1716 32.554 87.6761 31.6995L63.5682 17.9255C62.0713 17.0703 62.0713 14.9297 63.5682 14.0745L87.6761 0.30051Z" fill="#B0BEC5" />
    <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M26.2274 0.30051C27.7229 -0.553964 29.5913 0.515504 29.5913 2.22603V29.774C29.5913 31.4845 27.7229 32.554 26.2274 31.6995L2.11947 17.9255C0.622555 17.0703 0.622555 14.9297 2.11947 14.0745L26.2274 0.30051Z" fill="#B0BEC5" />
  </svg>
}

export default memo(SkipLeft);

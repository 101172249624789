import React from 'react';

const whiteNoiseStyle = {
  width: '100vw',
  height: '100%'
}

const ProcessOverlay: React.FC = () => {
  return (
    <div style={whiteNoiseStyle}>
      <section style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0, 0, 0, .8)',
        fontFamily: 'Roboto, sans-serif'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <svg style={{ animation: 'rotate 1s infinite linear' }} version="1.1" x="0px" y="0px"
            width="4rem" height="4rem" viewBox="0 0 50 50">
            <path fill="#FEC930"
              d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
            </path>
          </svg>

          <div style={{
            textAlign: 'center',
            color: '#dedede',
            marginTop: 16,
          }}>
            <strong
              style={{
                fontSize: '1.2em',
                display: 'block',
                fontWeight: 500
              }}
            >
              Estamos preparando o vídeo!
            </strong>
            <small
              style={{
                fontSize: '1em',
                display: 'block',
                fontWeight: 400,
                color: '#999999',
                marginTop: 4
              }}
            >
              (isso pode demorar um pouco)
            </small>
          </div>
        </div>

      </section>
    </div>
  );
};

export default ProcessOverlay;


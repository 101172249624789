import { BehaviorSubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

class FullscreenService {
  private fullscreenEnabled = false;
  private isFullscreenSupported = false;
  private browserPrefix = '';

  private fullscreenElement = new BehaviorSubject<Element | null>(null);

  constructor() {
    const [isFullscreenSupported, browserPrefix] = this.verifyIsFullscreenSupported();
    this.isFullscreenSupported = isFullscreenSupported;
    this.browserPrefix = browserPrefix;
    this.setFullscreenEnabled();
    this.defineFullscreenElement();
  }

  verifyIsFullscreenSupported = (): [boolean, string] => {
    const browserPrefixes = ['webkit', 'moz', 'o', 'ms', 'khtml'];

    if (typeof (document as any).cancelFullScreen !== 'undefined') {
      return [true, ''];
    }

    const currentPrefix = browserPrefixes.filter(prefix => typeof (document as any)[prefix + 'CancelFullScreen'] !== 'undefined');

    if (currentPrefix.length > 0) {
      return [true, currentPrefix[0]];
    }

    return [false, ''];
  }

  listenFullscreenElement() {
    return this.fullscreenElement.asObservable();
  }

  getFullscreenElement() {
    return this.fullscreenElement.value
  }

  defineFullscreenElement() {
    const browserPrefix = this.browserPrefix;
    let fullscreenElement: Element | null = null;

    if (browserPrefix && browserPrefix === 'moz') {
      fullscreenElement = (document as any).mozFullScreenElement;
    } else if (browserPrefix) {
      fullscreenElement = ((document as any)[browserPrefix + 'FullscreenElement'] as Element | null);
    } else {
      fullscreenElement = document.fullscreenElement;
    }

    this.fullscreenElement.next(fullscreenElement);
  }

  getIsFullscreen() {
    return this.listenFullscreenElement().pipe(switchMap(fullscreenElement => of(!!fullscreenElement)));
  }

  setFullscreenEnabled() {
    const browserPrefix = this.browserPrefix;

    if (browserPrefix && browserPrefix === 'moz') {
      this.fullscreenEnabled = (document as any).mozFullScreenEnabled;
    } else if (browserPrefix) {
      this.fullscreenEnabled = !!(document as any)[browserPrefix + 'FullscreenEnabled'];
    } else {
      this.fullscreenEnabled = !!document.fullscreenEnabled;
    }
  }

  getFullscreenEnabled() {
    return this.fullscreenEnabled;
  }

  getIsFullscreenSupported() {
    return this.isFullscreenSupported;
  }

  getBrowserPrefix() {
    return this.browserPrefix;
  }
}

export const fullscreenService = new FullscreenService();
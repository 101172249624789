import { useCallback, useEffect } from 'react';
import { fromEvent } from 'rxjs';

import { Actions, controlsService } from '../services/controls';
import { Events, eventsService } from '../services/events';
import PlayerService from '../services/player';

export const useVolumeActions = (playerService: React.MutableRefObject<PlayerService>, videoElementRef: React.MutableRefObject<HTMLVideoElement | null>) => {
  const requestVolumeChange = useCallback((value: number) => {
    playerService.current.getCurrentPlayerService().volume(value);
  }, [playerService])

  const requestMute = useCallback((value: boolean) => {
    playerService.current.getCurrentPlayerService().mute(value);
  }, [playerService]);

  useEffect(() => {
    const mutedSubscription = controlsService.on(Actions.CLICK_MUTED).subscribe(({ data }) => {
      requestMute(data)
    })
    return () => mutedSubscription.unsubscribe();
  }, [requestMute]);

  useEffect(() => {
    const volumeChangeSubscription = controlsService.on(Actions.CLICK_VOLUME_CHANGE).subscribe(({ data }) => {
      requestVolumeChange(data);
    })
    return () => volumeChangeSubscription.unsubscribe();
  }, [requestVolumeChange]);
}

export const useVolumeEvents = (videoElementRef: React.MutableRefObject<HTMLVideoElement | null>) => {
  useEffect(() => {
    const videoElement = videoElementRef.current;

    if (videoElement) {
      const volumeSubscription = fromEvent(videoElement, 'volumechange').subscribe(() => {
        eventsService.registerEvent({
          name: Events.PLAYER_VOLUME_CHANGED,
          event: { muted: videoElement.muted, volume: videoElement.volume }
        })
      });
      return () => volumeSubscription.unsubscribe();
    }
  }, [videoElementRef])

  useEffect(() => {
    const castDisconnectedSubscription = eventsService.on(Events.CHROMECAST_DISCONNECTED).subscribe(() => {
      eventsService.registerEvent({
        name: Events.PLAYER_VOLUME_CHANGED,
        event: {
          muted: videoElementRef.current?.muted,
          volume: videoElementRef.current?.volume
        }
      })
    })

    return () => castDisconnectedSubscription.unsubscribe();
  }, [videoElementRef])
}

import { useEffect } from 'react';
import { bufferToggle, filter } from 'rxjs';

import { Actions, controlsService } from '../services/controls';
import { Events, eventsService } from '../services/events';

export const useReplay = () => {
  useEffect(() => {
    const isEnded$ = eventsService.on(Events.PLAYER_ENDED);
    const seeked$ = eventsService.on(Events.PLAYER_SEEKED);

    const seekingEndedSubscription = eventsService
      .on(Events.PLAYER_SEEKING)
      .pipe(
        bufferToggle(isEnded$, () => seeked$),
        filter((seekEvents) => seekEvents.length > 0)
      )
      .subscribe(() => controlsService.registerAction({ name: Actions.CLICK_PLAY }));

    return () => seekingEndedSubscription.unsubscribe();
  }, [])
}

import Hls, { ErrorData, Events } from 'hls.js';
import { useEffect } from 'react';
import { filter, fromEvent } from 'rxjs';

export const useHlsFatalErrorHandler = (hls: Hls) => {
  useEffect(() => {
    const fatalErrorEvent$ = fromEvent<Events, ErrorData>(hls, Hls.Events.ERROR, (_, data: ErrorData) => data).pipe(filter(({ fatal }) => fatal));

    const networkErrorSubscription = fatalErrorEvent$.pipe(filter(({ type }) => type === Hls.ErrorTypes.NETWORK_ERROR)).subscribe(() => {
      console.log('fatal network error encountered, try to recover');
      hls.startLoad();
    });

    return () => networkErrorSubscription.unsubscribe();
  }, [hls]);
}

import { useEffect } from 'react';
import { filter, fromEvent, pluck } from 'rxjs';

import IChromecastMediaEvent from '../../interfaces/chromecastMediaEvent';
import { chromecastService } from '../../services/chromecast';
import { Events, eventsService } from '../../services/events';

export const useChromecastVolume = (playerController: any) => {
  useEffect(() => {
    if (playerController) {
      const castVolumeLevelChangedSubscription = fromEvent<IChromecastMediaEvent<number>>(playerController, (window as any).cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED)
        .pipe(pluck('value'))
        .subscribe((volumeLevel) => {
          eventsService.registerEvent({
            name: Events.PLAYER_VOLUME_CHANGED,
            event: {
              muted: chromecastService.getPlayer().isMuted,
              volume: volumeLevel
            }
          })
        })

      return () => {
        castVolumeLevelChangedSubscription.unsubscribe();
      }
    }
  }, [playerController]);

  useEffect(() => {
    if (playerController) {
      const castIsMutedChangedSubscription = fromEvent<IChromecastMediaEvent<boolean>>(playerController, (window as any).cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED)
        .pipe(
          filter((_, index) => index % 3 === 0),
          pluck('value')
        )
        .subscribe((isMuted) => {
          eventsService.registerEvent({
            name: Events.PLAYER_VOLUME_CHANGED,
            event: {
              muted: isMuted,
              volume: chromecastService.getPlayer().volumeLevel
            }
          })
        })

      return () => {
        castIsMutedChangedSubscription.unsubscribe();
      }
    }

  }, [playerController]);
}

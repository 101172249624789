import { filter, of, pluck, switchMap } from 'rxjs';

import { Events, eventsService } from './events';

class LoadingService {
  listenIsLoading() {
    return eventsService
      .listenEvents()
      .pipe(
        pluck('name'),
        filter(eventName => eventName === Events.PLAYER_LOADEND || eventName === Events.PLAYER_LOADSTART),
        switchMap(filteredEventName => filteredEventName === Events.PLAYER_LOADEND ? of(false) : of(true)),
      )
  }
}

export const loadingService = new LoadingService();

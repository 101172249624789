import { useEffect } from 'react';
import { combineLatest, filter, map, merge, startWith } from 'rxjs';

import { activeService } from '../services/active';
import { Actions, controlsService } from '../services/controls';
import { Events, eventsService } from '../services/events';
import { playPauseService } from '../services/playPause';

export const useActive = () => {
  useEffect(() => {
    const mergedUserActionEvents$ = activeService.listenMergedUserActionEvents();
    const requstOpenMenu$ = controlsService.on(Actions.REQUEST_OPEN_MENU).pipe(map(() => true));
    const menuClosed$ = eventsService.on(Events.PLAYER_MENU_CLOSED).pipe(map(() => false));
    const openMenu$ = merge(requstOpenMenu$, menuClosed$).pipe(startWith(false));

    const inactiveSubscription = combineLatest([mergedUserActionEvents$, playPauseService.listenIsPlaying(), openMenu$])
      .pipe(filter(([eventList, playerIsPlaying, menuIsOpen]) => (!menuIsOpen && playerIsPlaying && eventList.length === 0)))
      .subscribe(() => eventsService.registerEvent({ name: Events.PLAYER_INACTIVED }));

    return () => inactiveSubscription.unsubscribe();
  }, []);
}

import './style.css';

import { memo } from 'react';
import { useObservable } from 'react-use-observable';

import { durationService } from '../../../../../../services/duration';

export interface ITimeupdadte {
  duration: number;
  currentTime: number;
}

const Duration = () => {
  const [videoDuration] = useObservable(() => durationService.getVideoDuration(), [])
  const [videoCurrentTime] = useObservable(() => durationService.getVideoCurrentTime(), []);

  return (
    <div className="duration">
      {`${videoCurrentTime || '0:00'} / ${videoDuration || '0:00'}`}
    </div>
  );
}

export default memo(Duration);

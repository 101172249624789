import './style.css';

import React, { memo } from 'react';

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}

const Button: React.FC<IButtonProps> = ({ className, onClick, disabled, children, ...buttonProps }) => {
  return (
    <button
      {...buttonProps}
      className={`playerButton ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default memo(Button);

import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

export enum Actions {
  CLICK_PLAY = 'clickedPlay',
  CLICK_PAUSE = 'clickedPause',
  CLICK_REQUEST_FULLSCREEN = 'clickedRequestFullscreen',
  CLICK_EXIT_FULLSCREEN = 'clickedExitFullscreen',
  CLICK_REQUEST_PIP = 'clickedRequestPiP',
  CLICK_EXIT_PIP = 'clickedExitPiP',
  REQUEST_SEEK = 'requestSeek',
  REQUEST_OPEN_MENU = 'requestOpenMenu',
  REQUEST_CLOSE_MENU = 'requestCloseMenu',
  CLICK_VOLUME_CHANGE = 'clickedVolumeChange',
  CLICK_MUTED = 'clickedMuted',
  CLICK_LEVEL_CHANGE = 'clickedLevelChange',
  CLICK_RATE_CHANGE = 'clickedRateChange',
  CLICK_VIDEO = 'clickedVideo',
  CLICK_VIDEO_ACTIVE = 'clickedVideoActived',
  CLICK_SUBTITLE_CHANGE = 'clickedSubtitleChange',
  REQUEST_SKIP_FORWARD = 'requestFastFoward',
  REQUEST_SKIP_BACKWARD = 'requestFastBackward',
}

export interface IPlayersAction<T = any> {
  name: Actions;
  data?: T;
}

class ControlsService {
  private controlsActions$ = new Subject<IPlayersAction>();
  registerAction(action: IPlayersAction) {
    this.controlsActions$.next(action);
  }
  listenActions() {
    return this.controlsActions$.asObservable();
  }
  on<T = any>(action: Actions) {
    return this.controlsActions$.pipe(
      filter((item: IPlayersAction<T>) => item.name === action)
    )
  }
}

export const controlsService = new ControlsService();

import CryptoJS from 'crypto-js';
import { map, ReplaySubject } from 'rxjs';

import { IWatch } from '../../videozz-common/dtos/player/IWatch';
import { VIDEOZZ_CRYPTOGRAPHY } from '../config';
import { TTokenType } from '../interfaces/watch';
import { api } from './api';
import { errorHandlerService } from './errorHandler';

interface IActions {
  [key: string]: () => void;
}

class WatchService {
  private watchInfo$ = new ReplaySubject<IWatch>(1);
  private tokenType: TTokenType | null = null;

  getTokenType() {
    return this.tokenType;
  }

  setTokenType(tokenType: TTokenType) {
    this.tokenType = tokenType;
  }

  defineWatchInfo(token: string, referrer: string) {
    const actions: IActions = {
      embed: () => this.embed(token, referrer),
      direct: () => this.direct(token),
      watch: () => this.watch(token)
    }
    if (!!this.tokenType) {
      actions[this.tokenType]();
    }
  }

  getWatchInfo() {
    return this.watchInfo$.asObservable();
  }

  embed(token: string, referrer: string) {
    api.get<string>(`/embed?tokenKey=${token}&host=${referrer}`).pipe(
      map((data) => {
        const decryptography = CryptoJS.AES.decrypt(data, VIDEOZZ_CRYPTOGRAPHY);
        const decryptedWatchData: IWatch = JSON.parse(decryptography.toString(CryptoJS.enc.Utf8));
        return decryptedWatchData;
      })
    )
      .subscribe({
        next: (data) => this.watchInfo$.next(data),
        error: (err) => errorHandlerService.defineCurrentError({ type: 'api', data: errorHandlerService.getErrorDescriptionByErrorCode(err.response.status) })
      });
  }

  direct(token: string) {
    api.get<IWatch>(`/direct?token=${token}`)
      .subscribe({
        next: (data) => this.watchInfo$.next(data),
        error: (err) => errorHandlerService.defineCurrentError({ type: 'api', data: errorHandlerService.getErrorDescriptionByErrorCode(err.response.status) })
      });
  }

  watch(token: string) {
    api.get<IWatch>(`/watch?token=${token}`)
      .subscribe({
        next: (data) => this.watchInfo$.next(data),
        error: (err) => errorHandlerService.defineCurrentError({ type: 'api', data: errorHandlerService.getErrorDescriptionByErrorCode(err.response.status) })
      })
  }
}

export const watchService = new WatchService();

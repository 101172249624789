import axios, { AxiosRequestConfig } from 'axios';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { API_URL } from '../config';


type httpVerb = 'GET' | 'POST' | 'PUT' | 'DELETE';

interface IRequestOptions<D = any> {
  data?: D,
  token?: string;
}

class Api {

  constructor(private apiUrl: string) { }

  get<T>(url: string, token?: any) {
    return this.request<T>('GET', url, token);
  }

  post<T, D = any>(url: string, data: D) {
    return this.request<T>('POST', url, {
      data,
    });
  }

  put<T, D = any>(url: string, data: D, token?: any) {
    return this.request<T>('PUT', url, {
      data,
      token
    });
  }

  delete<T, D = any>(url: string, data: D) {
    return this.request<T>('DELETE', url, {
      data
    });
  }

  request<T = any, D = any>(method: httpVerb, url: string, options: IRequestOptions<D> = {}): Observable<T> {

    return of(true)
      .pipe(
        switchMap(() => {

          const config: AxiosRequestConfig = {
            ...options,
            ...{
              method,
              baseURL: this.apiUrl,
              url,
              data: options.data
            }
          }

          config.headers = config.headers || {};

          if (options && options.token) {
            config.headers.Authorization = `Bearer ${options.token}`;
          }

          const promise = axios.request<T>(config);

          return from(promise);
        }),
        map(result => result.data),
        tap(() => null)
      )
  }
}

export const apiRoot = new Api(API_URL);
export const api = new Api(API_URL + '/player');
import { useEffect } from 'react';

import { Actions, controlsService } from '../services/controls';
import { Events, eventsService } from '../services/events';
import { remoteControlService } from '../services/remoteControl';

export const useRemoteControl = () => {
  useEffect(() => {
    const castConnectedSubscription = eventsService
      .on(Events.CHROMECAST_CONNECTED)
      .subscribe(() => {
        controlsService.registerAction({ name: Actions.REQUEST_CLOSE_MENU });
        controlsService.registerAction({ name: Actions.CLICK_PAUSE });
        controlsService.registerAction({ name: Actions.CLICK_EXIT_PIP });
        remoteControlService.setRemoteControlMode(true);
      })

    return () => castConnectedSubscription.unsubscribe();
  }, []);

  useEffect(() => {
    const castDisconnectedSubscription = eventsService
      .on(Events.CHROMECAST_DISCONNECTED)
      .subscribe(() => remoteControlService.setRemoteControlMode(false))

    return () => castDisconnectedSubscription.unsubscribe();
  }, []);
}

import { useStoragePlaybackRate } from './storagePlaybackRate';
import { useStorageQuality } from './storageQuality';
import { useStorageSubtitle } from './storageSubtitle';
import { useStorageVolume } from './storageVolume';

export const useControlStorage = () => {
  useStorageQuality();
  useStoragePlaybackRate();
  useStorageVolume();
  useStorageSubtitle();
}

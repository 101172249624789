import { useDurationEvents } from './duration';
import { useFullScreenEvents } from './fullscreen';
import { usePiPEvents } from './pip';
import { usePlaybackRateEvents } from './playbackRate';
import { usePlayPauseEvents } from './playPause';
import { useRangesEvents } from './ranges';
import { useSeekEvents } from './seek';
import { useVideoEnded } from './videoEnded';
import { useVolumeEvents } from './volume';

export const useVideoEventCatcher = (videoElementRef: React.MutableRefObject<HTMLVideoElement | null>) => {
  usePlayPauseEvents(videoElementRef);
  useDurationEvents(videoElementRef);
  useFullScreenEvents();
  useRangesEvents(videoElementRef);
  useVideoEnded(videoElementRef);
  usePiPEvents(videoElementRef);
  useVolumeEvents(videoElementRef);
  usePlaybackRateEvents(videoElementRef);
  useSeekEvents(videoElementRef);
}

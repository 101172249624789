import { memo } from 'react';

const VolumeDownIcon = () => {
  return (
    <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24"
    >
      <path fill="currentColor"
        d="M18,12c0-2.4-1.4-4.4-3.3-5.4v10.7C16.6,16.4,18,14.4,18,12z M0,9.3v5.3C0,15.4,0.6,16,1.3,16h4l4.4,4.4
    c0.8,0.8,2.3,0.2,2.3-0.9V4.5c0-1.2-1.4-1.8-2.3-0.9L5.3,8h-4C0.6,8,0,8.6,0,9.3z">
      </path>
    </svg>
  )
}

export default memo(VolumeDownIcon);

import { memo } from 'react';


const RequestFullscreenIcon = () => {
  return (
    <svg
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path
        fill='currentColor'
        d="M1.7,15.4c-0.9,0-1.7,0.8-1.7,1.7v5.1C0,23.2,0.8,24,1.7,24h5.1c0.9,0,1.7-0.8,1.7-1.7s-0.8-1.7-1.7-1.7H3.4v-3.4
    C3.4,16.2,2.7,15.4,1.7,15.4z M1.7,8.6c0.9,0,1.7-0.8,1.7-1.7V3.4h3.4c0.9,0,1.7-0.8,1.7-1.7S7.8,0,6.9,0H1.7C0.8,0,0,0.8,0,1.7v5.1
    C0,7.8,0.8,8.6,1.7,8.6z M20.6,20.6h-3.4c-0.9,0-1.7,0.8-1.7,1.7s0.8,1.7,1.7,1.7h5.1c0.9,0,1.7-0.8,1.7-1.7v-5.1
    c0-0.9-0.8-1.7-1.7-1.7s-1.7,0.8-1.7,1.7V20.6z M15.4,1.7c0,0.9,0.8,1.7,1.7,1.7h3.4v3.4c0,0.9,0.8,1.7,1.7,1.7S24,7.8,24,6.9V1.7
    C24,0.8,23.2,0,22.3,0h-5.1C16.2,0,15.4,0.8,15.4,1.7z">
      </path>
    </svg>
  )
}

export default memo(RequestFullscreenIcon);


import { useFastShortcut } from './fastShortcut';
import { useFullscreenShortcut } from './fullscreenShortcut';
import { useKeyPressEvent } from './keypressEvent';
import { useMuteShortcut } from './muteShortcut';
import { usePlayPauseShortcut } from './playPauseShortcut';
import { useVolumeShortcut } from './volumeShortcut';

export const useShortcuts = () => {
  usePlayPauseShortcut();
  useMuteShortcut();
  useFullscreenShortcut();
  useVolumeShortcut();
  useKeyPressEvent();
  useFastShortcut();
}

import { createContext, useEffect, useState } from 'react';

import { Actions, controlsService } from '../../services/controls';
import { Events, eventsService } from '../../services/events';

export interface IPlayerContext {
  openMenu: boolean;
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PlayerContext = createContext({} as IPlayerContext);

// TODO remove this context
const MenuProvider: React.FC = ({ children }) => {
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    const requestExitMenuSubscription = controlsService
      .on(Actions.REQUEST_CLOSE_MENU)
      .subscribe(() => setOpenMenu(false));

    return () => requestExitMenuSubscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (!openMenu) {
      eventsService.registerEvent({ name: Events.PLAYER_MENU_CLOSED })
    }
  }, [openMenu])

  return (
    <PlayerContext.Provider value={{ openMenu, setOpenMenu }}>
      {children}
    </PlayerContext.Provider>
  )
}

export default MenuProvider;

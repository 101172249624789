import { useEffect } from 'react';

import { progressService } from '../services/progress';

export const useProgress = (inactivated?: boolean) => {
  useEffect(() => {
    if (inactivated) {
      return;
    }
    const progressTickerSubscription = progressService.listenProgressTicker().subscribe(() => progressService.updateProgress());
    return () => progressTickerSubscription.unsubscribe();
  }, [inactivated]);
}

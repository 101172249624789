import { Events as hlsEvents } from 'hls.js';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

export enum Events {
  PLAYER_PLAYED = 'play',
  PLAYER_LOADSTART = 'loadstart',
  PLAYER_LOADEND = 'loadend',
  PLAYER_PAUSED = 'pause',
  PLAYER_FULLSCREEN_CHANGED = 'fullscreenchange',
  PLAYER_TIMEUPDATE = 'timeupdate',
  PLAYER_DURATIONCHANGE = 'durationchange',
  PLAYER_SEEKING = 'seeking',
  PLAYER_SEEKED = 'seeked',
  PLAYER_BUFFERED_TIME = 'bufferedTime',
  PLAYER_ENDED = 'ended',
  PLAYER_ACTIVED = 'actived',
  PLAYER_INACTIVED = 'inactived',
  PLAYER_PIP_CHANGED = 'pipchange',
  PLAYER_RATE_CHANGED = 'ratechange',
  CHROMECAST_CONNECTED = 'chromecastConnected',
  CHROMECAST_DISCONNECTED = 'chromecastDisconnected',
  CHROMECAST_LOADED_MEDIA = 'chromecastLoadedMedia',
  PLAYER_VOLUME_CHANGED = 'volumechange',
  PLAYER_MANIFEST_PARSED = 'manifestParsed',
  PLAYER_MENU_CLOSED = 'menuClosed',
  PLAYER_KEY_PRESSED = 'keyPressed'
}

export interface IPlayersEvent<T = any> {
  name: Events | hlsEvents;
  event?: T;
}

class EventsService {
  private playerEvents$ = new Subject<IPlayersEvent>();

  registerEvent(event: IPlayersEvent) {
    this.playerEvents$.next(event);
  }

  listenEvents() {
    return this.playerEvents$.asObservable();
  }

  on<T = any>(event: Events | hlsEvents) {
    return this.playerEvents$.pipe(
      filter((item: IPlayersEvent<T>) => item.name === event),
    )
  }
}

export const eventsService = new EventsService();

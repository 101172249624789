import { map, mergeWith, pluck, take } from 'rxjs';
import { distinct, filter } from 'rxjs/operators';

import { api } from './api';
import { chromecastService } from './chromecast';
import { Events, eventsService } from './events';
import { rangesService } from './ranges';
import { seekTimeService } from './seekTime';
import { transferredService } from './transferred';


class ProgressService {
  private sessionToken = '';

  setSessionToken(value: string) {
    this.sessionToken = value;
  }

  listenPauseEvents() {
    return eventsService
      .listenEvents()
      .pipe(
        pluck('name'),
        filter((filteringItem) => filteringItem === Events.PLAYER_PAUSED || filteringItem === Events.PLAYER_ENDED)
      )
  }

  listenProgressTicker() {
    return eventsService.on<number>(Events.PLAYER_TIMEUPDATE).pipe(
      pluck('event'),
      map((time) => Math.floor(time || 0)),
      filter((time) => time % 10 === 0),
      distinct(),
      mergeWith(this.listenPauseEvents()),
    )
  }

  updateProgress() {
    const data = {
      seekTime: seekTimeService.getSeekTime(),
      ranges: rangesService.getWatchedRanges(),
      transferred: transferredService.getTransferred(),
      cast: chromecastService.getCast()
    };
    api.put('/progress', data, this.sessionToken).pipe(take(1)).subscribe({ error: console.error });
  }
}

export const progressService = new ProgressService();

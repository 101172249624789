import * as Sentry from "@sentry/react";
import Hls, { ErrorData, Events } from "hls.js";
import { useEffect } from "react";
import { filter, fromEvent, map, tap } from "rxjs";

import { errorHandlerService } from "../services/errorHandler";

export const useHlsErrorCatcher = (hls: Hls) => {
  useEffect(() => {
    if (Hls.isSupported()) {
      const fatalErrorSubscription = fromEvent<Events, ErrorData>(
        hls,
        Hls.Events.ERROR,
        (_, data: ErrorData) => data
      )
        .pipe(
          filter(({ fatal }) => fatal),
          filter(({ type }) => type !== Hls.ErrorTypes.NETWORK_ERROR),
          tap((errorData) => {
            console.error("HlsError: ", errorData);
            Sentry.captureMessage(
              `HlsError: [${errorData.type}: ${errorData.details}]`
            );
          }),
          map((errorData) => `[${errorData.type}: ${errorData.details}]`)
        )
        .subscribe((errorMessage) => {
          errorHandlerService.defineCurrentError({
            type: "hls",
            data: errorMessage,
          });
        });

      return () => fatalErrorSubscription.unsubscribe();
    }
  }, [hls]);
};

import { memo } from 'react';

const ExitMenu = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.08147 0.229775C0.886211 0.0345127 0.569628 0.0345127 0.374366 0.229775C0.179104 0.425037 0.179104 0.741619 0.374366 0.936882L4.29289 4.85541L0.22976 8.91854C0.0344974 9.1138 0.0344975 9.43039 0.22976 9.62565C0.425022 9.82091 0.741604 9.82091 0.936866 9.62565L5 5.56252L9.04524 9.60775C9.2405 9.80302 9.55708 9.80302 9.75235 9.60775C9.94761 9.41249 9.94761 9.09591 9.75235 8.90065L5.70711 4.85541L9.60774 0.954777C9.803 0.759515 9.803 0.442932 9.60774 0.24767C9.41248 0.0524079 9.09589 0.0524081 8.90063 0.24767L5 4.1483L1.08147 0.229775Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default memo(ExitMenu);

import './style.css';

import MobileDetect from 'mobile-detect';
import { useEffect, useMemo, useState } from 'react';

import { watchService } from '../../../../../../services/watch';
import LogoSvg from '../../../../../icons/Logo';
import LogoIconSvg from '../../../../../icons/LogoIcon';
import Button from '../Button';

const SafeVideoLogo: React.VFC = () => {
  const mobileDetect = useMemo(() => new MobileDetect(window.navigator.userAgent), []);
  const [disable, setDisable] = useState(false);
  const handleClick = () => {
    if (watchService.getTokenType() === "embed") {
      window.open("https://safevideo.com");
    }
  };

  useEffect(() => {
    if (watchService.getTokenType() !== "embed") {
      setDisable(true);
    }
  }, []);

  return (
    <Button onClick={handleClick} className={disable ? 'buttonNotEmbed' : ''}>
      {!!mobileDetect.mobile() ? <LogoIconSvg /> : <LogoSvg />}
    </Button>
  );
};

export default SafeVideoLogo;

import { useEffect } from 'react';
import { fromEvent } from 'rxjs';

import { Events, eventsService } from '../services/events';

export const useVideoEnded = (videoElementRef: React.MutableRefObject<HTMLVideoElement | null>) => {
  const videoElement = videoElementRef.current;

  useEffect(() => {
    if (videoElement) {
      fromEvent(videoElement, 'ended').subscribe(data => {
        eventsService.registerEvent({
          name: Events.PLAYER_ENDED
        })
      })
    }
  }, [videoElement])
}
export interface ITimeRange {
  start: number;
  end: number;
  playedAt: string;
}

class RangesService {
  private watchedRanges: ITimeRange[] = [];

  createAndAddWatchRange(currentTime: number) {
    const range = {
      start: currentTime,
      end: currentTime,
      playedAt: new Date().toUTCString()
    };

    this.watchedRanges = [...this.watchedRanges, range];
  }

  updateLastRange(time: number) {
    if (this.watchedRanges.length > 0) {
      this.watchedRanges[this.watchedRanges.length - 1].end = time;
    }
  }

  getWatchedRanges() {
    return this.watchedRanges;
  }

}

export const rangesService = new RangesService();
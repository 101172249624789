import { memo } from 'react';

const VolumeOffIcon = () => {
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M0.8,0.8c-0.5,0.5-0.5,1.4,0,1.9l4.9,4.9L5.3,8h-4C0.6,8,0,8.6,0,9.3v5.3C0,15.4,0.6,16,1.3,16h4l4.4,4.4
    c0.8,0.8,2.3,0.2,2.3-0.9v-5.6l5.6,5.6c-0.7,0.5-1.4,0.9-2.1,1.2c-0.5,0.2-0.8,0.7-0.8,1.2c0,1,1,1.6,1.9,1.2c1.1-0.4,2.1-1,3-1.7
    l1.8,1.8c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9L2.7,0.8C2.2,0.3,1.4,0.3,0.8,0.8z M21.3,12c0,1.1-0.2,2.1-0.5,3.1l2,2
    c0.7-1.6,1.2-3.3,1.2-5.2c0-5.1-3.2-9.5-7.7-11.2c-0.8-0.3-1.6,0.3-1.6,1.1v0.3c0,0.5,0.3,0.9,0.8,1.1C18.9,4.7,21.3,8.1,21.3,12z
     M9.7,3.6L9.5,3.8L12,6.4V4.6C12,3.4,10.6,2.8,9.7,3.6z M18,12c0-2.4-1.4-4.4-3.3-5.4V9l3.3,3.3C18,12.2,18,12.1,18,12z"
      />
    </svg>
  )
}

export default memo(VolumeOffIcon);

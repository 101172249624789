import React, { memo } from 'react';
import { useObservable } from 'react-use-observable';
import { map, take } from 'rxjs';

import { Events, eventsService } from '../../../../../../services/events';
import Play from '../Play';

const BigPlay: React.FC = () => {
  const [videoWasPlayed] = useObservable(() => eventsService.on(Events.PLAYER_PLAYED).pipe(
    map(() => true),
    take(1),
  ), []);

  if (!videoWasPlayed) {
    return (<Play size="large" />);
  }

  return (<></>);
}

export default memo(BigPlay);

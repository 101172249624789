import { useEffect } from 'react';
import { useObservable } from 'react-use-observable';
import { merge } from 'rxjs';
import { pluck, switchMap, take, tap } from 'rxjs/operators';

import { chromecastService } from '../../services/chromecast';
import { Actions, controlsService } from '../../services/controls';
import { Events, eventsService } from '../../services/events';
import { remoteControlService } from '../../services/remoteControl';
import { seekTimeService } from '../../services/seekTime';

export const useChromecastReplay = (playlistURL: string) => {
  const [remoteControlActive] = useObservable(() => remoteControlService.getRemoteControlMode(), []);

  useEffect(() => {
    if (remoteControlActive) {
      const clickPlay$ = controlsService.on(Actions.CLICK_PLAY);
      const requestSeek$ = controlsService.on(Actions.REQUEST_SEEK).pipe(
        pluck('data'),
        tap(seekData => seekData !== undefined && seekTimeService.setSeekTime(seekData))
      );
      const replaySubscription = eventsService.on(Events.PLAYER_ENDED)
        .pipe(
          switchMap(() => merge(clickPlay$, requestSeek$).pipe(take(1))),
          switchMap(() => chromecastService.loadMedia(playlistURL)),
        )
        .subscribe(() => eventsService.registerEvent({ name: Events.CHROMECAST_LOADED_MEDIA }));

      return () => replaySubscription.unsubscribe();
    }

  }, [playlistURL, remoteControlActive]);
}

import { useEffect } from 'react';
import { useObservable } from 'react-use-observable';
import { fromEvent, mergeWith, take, tap } from 'rxjs';

import { Events, eventsService } from '../services/events';
import { rangesService } from '../services/ranges';

export const useRangesEvents = (videoElementRef: React.MutableRefObject<HTMLVideoElement | null>) => {
  useEffect(() => {
    const videoElement = videoElementRef.current;

    if (videoElement) {
      fromEvent(videoElement, 'seeking').subscribe(data => {
        eventsService.registerEvent({
          name: Events.PLAYER_SEEKING,
        })
      });
    }
  }, [videoElementRef]);
}

export const useWatchedRanges = (videoElementRef: React.MutableRefObject<HTMLVideoElement | null>) => {
  useObservable(() => eventsService.on(Events.PLAYER_SEEKING).pipe(
    mergeWith(eventsService.on(Events.PLAYER_PLAYED).pipe(
      take(1)
    )),
    tap(() => {
      const videoElement = videoElementRef.current;

      if (videoElement) {
        rangesService.createAndAddWatchRange(videoElement.currentTime)
      }
    })
  ), []);
}

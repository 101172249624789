import Hls from 'hls.js';
import { useEffect } from 'react';
import { useObservable } from 'react-use-observable';
import { fromEvent, tap } from 'rxjs';

import { eventsService } from '../services/events';
import { transferredService } from '../services/transferred';

export const useBufferEvents = (hls: Hls) => {
  useEffect(() => {
    if (Hls.isSupported()) {
      fromEvent(hls, Hls.Events.FRAG_BUFFERED).subscribe(data => {
        eventsService.registerEvent({
          name: Hls.Events.FRAG_BUFFERED,
          event: data
        })
      });
    }
  }, [hls]);
}

export const useBufferCatcher = () => {
  useObservable(() => eventsService.on(Hls.Events.FRAG_BUFFERED).pipe(
    tap((data) => {
      transferredService.setTransferred(data.event[1].frag.stats.total);
    })
  ), []);
}

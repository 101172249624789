import { useEffect } from 'react';
import { fromEvent, pluck, startWith } from 'rxjs';

import IChromecastMediaEvent from '../../interfaces/chromecastMediaEvent';
import { Events, eventsService } from '../../services/events';

type playerStateChangedValue = 'BUFFERING' | 'PLAYING' | 'PAUSED';

export const useChromecastPlayPauseEvents = (playerController: any) => {
  useEffect(() => {
    const castDisconectedSubscription = eventsService
      .on(Events.CHROMECAST_DISCONNECTED)
      .subscribe(() => eventsService.registerEvent({ name: Events.PLAYER_PAUSED }));

    return () => castDisconectedSubscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (playerController) {
      const castPlayerStateChangedSubscription = fromEvent<IChromecastMediaEvent<playerStateChangedValue>>(playerController, (window as any).cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED)
        .pipe(
          pluck('value'),
          startWith('PAUSED' as playerStateChangedValue)
        )
        .subscribe((playerState) => {
          const playerStateEventNames: { [key: string]: () => Events } = {
            BUFFERING: () => Events.PLAYER_LOADSTART,
            PLAYING: () => Events.PLAYER_PLAYED,
            PAUSED: () => Events.PLAYER_PAUSED,
            IDLE: () => Events.PLAYER_ENDED
          }

          if (playerStateEventNames[playerState]) {
            eventsService.registerEvent({ name: playerStateEventNames[playerState]() })
          }

          if (playerState !== 'BUFFERING') {
            eventsService.registerEvent({ name: Events.PLAYER_LOADEND })
          }
        })

      return () => {
        castPlayerStateChangedSubscription.unsubscribe();
      }
    }

  }, [playerController]);
}

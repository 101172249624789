import IPlayerService from '../interfaces/playerService';
import ChromecastPlayerService from './chromecastPlayer';
import HlsPlayerService from './hlsPlayer';
import { remoteControlService } from './remoteControl';

export default class PlayerService {
  private hlsPlayerService: HlsPlayerService;
  private chromecastPlayerService: ChromecastPlayerService;

  constructor(readonly videoElementRef: React.MutableRefObject<HTMLVideoElement | null>) {
    this.hlsPlayerService = new HlsPlayerService(videoElementRef);
    this.chromecastPlayerService = new ChromecastPlayerService();
  }

  getCurrentPlayerService(): IPlayerService {
    if (remoteControlService.getRemoteControlModeValue()) {
      return this.chromecastPlayerService;
    }
    return this.hlsPlayerService;
  }
}

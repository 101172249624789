import { useEffect, useMemo } from 'react';
import { BehaviorSubject, fromEvent } from 'rxjs';

import { Actions, controlsService } from '../services/controls';
import { volumeService } from '../services/volume';

export const useMuteShortcut = () => {
  const isMuted$ = useMemo(() => new BehaviorSubject(false), []);

  useEffect(() => {
    const mutedSubscription = volumeService.listenIsMuted().subscribe((isMuted) => isMuted$.next(isMuted))
    return () => mutedSubscription.unsubscribe();
  }, [isMuted$]);

  useEffect(() => {
    const mutedSubsciption = fromEvent<KeyboardEvent>(document, 'keydown').subscribe(data => {
      if (data.key.toLocaleLowerCase() === 'm') {
        controlsService.registerAction({ name: Actions.CLICK_MUTED, data: !isMuted$.value });
      }
    })

    return () => mutedSubsciption.unsubscribe();
  }, [isMuted$]);
}

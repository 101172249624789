import { useEffect } from 'react';
import { useObservable } from 'react-use-observable';

import { Actions, controlsService } from '../services/controls';
import { levelsService } from '../services/levels';
import { useLocalStorage } from './localStorage';

export const useStorageQuality = () => {
  const localStorageInstance = useLocalStorage();
  const [levels] = useObservable(() => levelsService.getLevels(), []);

  useEffect(() => {
    if (localStorageInstance) {
      const QualitySubscription = controlsService.on<number>(Actions.CLICK_LEVEL_CHANGE).subscribe(({ data: quality }) => {
        localStorageInstance.setItem('quality', `${quality}`);
      })
      return () => QualitySubscription.unsubscribe();
    }
  }, [localStorageInstance]);

  useEffect(() => {
    if (localStorageInstance && levels) {
      const qualityFromStorage = parseInt(localStorageInstance.getItem('quality')!);
      const quality = qualityFromStorage === 0 || !!qualityFromStorage ? qualityFromStorage : -1;
      const isQualityAvailable = levels.find(item => item.data === quality);

      controlsService.registerAction({
        name: Actions.CLICK_LEVEL_CHANGE,
        data: isQualityAvailable ? quality : -1
      })
    }
  }, [localStorageInstance, levels])
}

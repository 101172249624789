import { memo } from 'react';

const ExitFullscreenIcon = () => {
  return (
    <svg
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path
        fill='currentColor'
        d="M1.7,18.9h3.4v3.4c0,0.9,0.8,1.7,1.7,1.7s1.7-0.8,1.7-1.7v-5.1c0-0.9-0.8-1.7-1.7-1.7H1.7c-0.9,0-1.7,0.8-1.7,1.7
    S0.8,18.9,1.7,18.9z M5.1,5.1H1.7C0.8,5.1,0,5.9,0,6.9s0.8,1.7,1.7,1.7h5.1c0.9,0,1.7-0.8,1.7-1.7V1.7C8.6,0.8,7.8,0,6.9,0
    S5.1,0.8,5.1,1.7V5.1z M17.1,24c0.9,0,1.7-0.8,1.7-1.7v-3.4h3.4c0.9,0,1.7-0.8,1.7-1.7s-0.8-1.7-1.7-1.7h-5.1
    c-0.9,0-1.7,0.8-1.7,1.7v5.1C15.4,23.2,16.2,24,17.1,24z M18.9,5.1V1.7c0-0.9-0.8-1.7-1.7-1.7s-1.7,0.8-1.7,1.7v5.1
    c0,0.9,0.8,1.7,1.7,1.7h5.1c0.9,0,1.7-0.8,1.7-1.7s-0.8-1.7-1.7-1.7H18.9z" />
    </svg>
  )
}

export default memo(ExitFullscreenIcon);


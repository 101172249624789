import './style.css';

import React from 'react';

import { usePlayerContext } from '../../../../../../contexts/PlayerContext/hook';
import { Actions, controlsService } from '../../../../../../services/controls';
import SettingsIcon from '../../../../../icons/Settings';
import Button from '../Button';

interface ISettingsProps {
  onClick?: (event: React.MouseEvent) => void;
}

const Settings: React.FC<ISettingsProps> = ({ onClick }: ISettingsProps) => {
  const { setOpenMenu } = usePlayerContext();

  const handleClick = (event: React.MouseEvent) => {
    controlsService.registerAction({ name: Actions.REQUEST_OPEN_MENU });
    setOpenMenu(prevPlayerState => !prevPlayerState);
    onClick && onClick(event);
  }

  return (
    <Button onClick={handleClick}>
      <SettingsIcon />
    </Button>
  )
}

export default Settings;

import { MutableRefObject, useEffect, useRef } from 'react';
import { filter, fromEvent, map, switchMap } from 'rxjs';

import { TLanguage } from '../interfaces/watch';
import { Actions, controlsService } from '../services/controls';

export const useSubtitleSelector = (playerElementRef: MutableRefObject<HTMLVideoElement | null>) => {
  const videoElement = playerElementRef.current;
  const loadedSubtitleListRef = useRef<string[]>([]);

  useEffect(() => {
    if (!videoElement) return;

    controlsService.on<TLanguage>(Actions.CLICK_SUBTITLE_CHANGE).subscribe(({ data }) => {
      const trackList = Array.from(videoElement.textTracks);

      trackList.forEach((track) => {
        track.mode = track.language === data ? 'hidden' : 'disabled';
      });
    });
  }, [videoElement]);

  useEffect(() => {
    if (!videoElement) return;

    const trackElement$ = fromEvent(videoElement.textTracks, 'change').pipe(
      map((textTrackChangeEvent) => {
        const textTrackList = Array.from(textTrackChangeEvent.currentTarget as TextTrackList);
        const selectedTextTrack = textTrackList.find(textTrack => textTrack.mode === 'hidden');
        return selectedTextTrack;
      }),
      filter((selectedTextTrack) => !!selectedTextTrack),
      map((selectedTextTrack) => {
        const trackElementList = Array.from(document.querySelectorAll('track')) as HTMLTrackElement[];
        const trackElement = trackElementList.find(track => track.srclang === selectedTextTrack?.language);
        return trackElement;
      }));

    const notLoadedTrackElementSubscription = trackElement$.pipe(
      filter((trackElement) => !!trackElement),
      switchMap(trackElement => fromEvent(trackElement!, 'load')),
    ).subscribe((trackLoadEvent) => {
      const trackElement = trackLoadEvent.target as HTMLTrackElement;
      const textTrack = trackElement.track;

      const VTTCueList = Array.from(textTrack.cues!) as VTTCue[];
      VTTCueList.forEach(VTTCueItem => VTTCueItem.line = -4);

      textTrack.mode = 'showing';
      loadedSubtitleListRef.current = [...loadedSubtitleListRef.current, trackElement.srclang];
    });

    const loadedTrackElementSubscription = trackElement$.pipe(
      filter(trackElement => !!trackElement && loadedSubtitleListRef.current.includes(trackElement.srclang)),
    ).subscribe(trackElement => {
      const textTrack = trackElement!.track;
      textTrack.mode = 'showing';
    })

    return () => {
      loadedTrackElementSubscription.unsubscribe();
      notLoadedTrackElementSubscription.unsubscribe();
    };
  }, [videoElement])
}

import { useEffect } from 'react';
import { pluck } from 'rxjs';

import { Actions, controlsService } from '../services/controls';
import { Events, eventsService } from '../services/events';
import { useLocalStorage } from './localStorage';

export const useStoragePlaybackRate = () => {
  const localStorageInstance = useLocalStorage();

  useEffect(() => {
    if (localStorageInstance) {
      const PlaybackSubscription = eventsService.on<number>(Events.PLAYER_RATE_CHANGED).pipe(
        pluck('event')
      ).subscribe(playbackRate => localStorageInstance.setItem('playbackRate', `${playbackRate || 1}`))
      return () => PlaybackSubscription.unsubscribe();
    }
  }, [localStorageInstance])

  useEffect(() => {
    if (localStorageInstance) {
      controlsService.registerAction({
        name: Actions.CLICK_RATE_CHANGE,
        data: parseFloat(localStorageInstance.getItem('playbackRate') || '1')
      })
    }
  }, [localStorageInstance])
}

import React from 'react';
import { useObservable } from 'react-use-observable';

import { Actions, controlsService } from '../../../../../../services/controls';
import { seekService } from '../../../../../../services/seek';
import { ProgressSlider } from '../../../../../shared/ProgressSlider';


const SeekSlider: React.FC = () => {
  const [videoDuration] = useObservable(() => seekService.getVideoDuration(), [])
  const [videoCurrentTime] = useObservable(() => seekService.getVideoCurrentTime(), []);
  const [bufferedTime] = useObservable(() => seekService.getBufferedTime(), [])

  return (
    <ProgressSlider
      max={videoDuration || 0}
      currentTime={videoCurrentTime || 0}
      progress={(bufferedTime || 0)}
      onChange={(time) => controlsService.registerAction({ name: Actions.REQUEST_SEEK, data: time })}
      limitTimeTooltipBySides={true}
      secondsPrefix="00:"
      minutesPrefix="00:"
    />
  );
}

export default SeekSlider;

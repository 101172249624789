import IPlayerService from '../interfaces/playerService';
import { chromecastService } from './chromecast';
import { errorHandlerService } from './errorHandler';

export default class ChromecastPlayerService implements IPlayerService {
  public play = () => {
    if (chromecastService.getCast()) {
      chromecastService.getPlayerControllerValue().playOrPause();
    } else {
      errorHandlerService.defineCurrentError({ type: 'hls', data: errorHandlerService.getErrorDescriptionByErrorCode(523) });
    }
  }

  public pause = this.play;

  public seek = (desiredSeektime: number) => {
    if (chromecastService.getPlayerControllerValue()) {
      chromecastService.getPlayer().currentTime = desiredSeektime;
      chromecastService.getPlayerControllerValue().seek();
    }
  }

  public mute = () => {
    if (chromecastService.getPlayerControllerValue()) {
      chromecastService.getPlayerControllerValue().muteOrUnmute();
    }
  }

  public volume = (desiredVolumeLevel: number) => {
    if (chromecastService.getPlayerControllerValue()) {
      const formatedDesiredVolumeLevel = desiredVolumeLevel * 0.01;
      chromecastService.getPlayer().volumeLevel = formatedDesiredVolumeLevel;
      chromecastService.getPlayerControllerValue().setVolumeLevel();
    }
  }
}

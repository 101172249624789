import './style.css';

import MobileDetect from 'mobile-detect';
import React, { useMemo } from 'react';

interface IChromecastProps {
  onClick?: (event: React.MouseEvent) => void;
}

const Chromecast: React.FC<IChromecastProps> = ({ onClick }: IChromecastProps) => {
  const mobileDetect = useMemo(() => new MobileDetect(window.navigator.userAgent), []);
  return (
    <div
      onClick={onClick}
      dangerouslySetInnerHTML={{ __html: `<google-cast-launcher ${!!mobileDetect.mobile() ? 'class="chromecast--mobile"' : ''}/>` }}
    />
  );
}

export default Chromecast;

import { memo } from 'react';

import { usePlayerContext } from '../../../contexts/PlayerContext/hook';

const SettingsIcon = () => {
  const { openMenu } = usePlayerContext();

  return (
    <svg
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      className={`settingsIcon ${openMenu ? "settingsRotate" : ""}`}
    >
      <path
        fill='currentColor'
        d="M21.3,12c0-0.3,0-0.5,0-0.8l2.3-1.7c0.5-0.4,0.6-1,0.3-1.6l-2.3-3.9c-0.3-0.5-1-0.7-1.5-0.5l-2.7,1.1
    c-0.5-0.3-0.9-0.6-1.4-0.8l-0.4-2.8C15.5,0.5,14.9,0,14.3,0H9.7C9.1,0,8.5,0.5,8.5,1.1L8.1,3.8c-0.5,0.2-1,0.5-1.4,0.8L4,3.6
    c-0.6-0.2-1.2,0-1.5,0.5L0.2,8C-0.1,8.5,0,9.2,0.5,9.5l2.3,1.7c0,0.3,0,0.5,0,0.8s0,0.5,0,0.8l-2.3,1.7c-0.5,0.4-0.6,1-0.3,1.6
    L2.5,20c0.3,0.5,1,0.7,1.5,0.5l2.7-1.1c0.5,0.3,0.9,0.6,1.4,0.8L8.5,23c0.1,0.6,0.6,1.1,1.2,1.1h4.6c0.6,0,1.1-0.5,1.2-1.1l0.4-2.8
    c0.5-0.2,1-0.5,1.4-0.8l2.7,1.1c0.6,0.2,1.2,0,1.5-0.5l2.3-3.9c0.3-0.5,0.2-1.2-0.3-1.6l-2.3-1.7C21.2,12.6,21.3,12.3,21.3,12z
     M12,16.2c-2.4,0-4.3-1.9-4.3-4.2S9.7,7.8,12,7.8s4.3,1.9,4.3,4.2S14.4,16.2,12,16.2z"
      />
    </svg>
  )
}

export default memo(SettingsIcon);

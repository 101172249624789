import "./style.css";

import { IWatch } from "../../../../../videozz-common/dtos/player/IWatch";
import { useProgress } from "../../../../hooks/progress";
import Arrazo from "../../../shared/Arrazo";
import ControlsOverlay from "./ControlsOverlay";
import Loading from "./Loading";
import Video from "./Video";

interface IPlayerProps {
  watchInfo: IWatch;
}

const Player: React.FC<IPlayerProps> = ({ watchInfo }) => {
  useProgress(watchInfo.previewMode);

  return (
    <section id="safevideo-player" className="player">
      <Video
        loop={watchInfo.previewMode}
        autoplay={false}
        playlistURL={watchInfo.playlist}
        seekTime={watchInfo.seekTime}
        thumbnail={watchInfo.thumbnail}
      />

      <ControlsOverlay isPreviewMode={watchInfo.previewMode} />

      <Loading />
      <Arrazo />
    </section>
  );
};

export default Player;

import * as Sentry from '@sentry/react';
import { useCallback, useEffect } from 'react';
import { fromEvent } from 'rxjs';

import { Actions, controlsService } from '../services/controls';
import { Events, eventsService } from '../services/events';

export const usePlaybackRateActions = (videoElementRef: React.MutableRefObject<HTMLVideoElement | null>) => {
  const requestSpeedChange = useCallback((value: number) => {
    if (videoElementRef.current) {
      videoElementRef.current.playbackRate = value;
    }
  }, [videoElementRef])

  useEffect(() => {
    const SpeedChangeSubscription = controlsService.on(Actions.CLICK_RATE_CHANGE).subscribe(({ data }) => {
      requestSpeedChange(data);
    })
    return () => SpeedChangeSubscription.unsubscribe();
  }, [requestSpeedChange]);
}

export const usePlaybackRateEvents = (videoElementRef: React.MutableRefObject<HTMLVideoElement | null>) => {
  useEffect(() => {
    const videoElement = videoElementRef.current;

    if (videoElement) {
      const SpeedSubscription = fromEvent(videoElement, 'ratechange').subscribe(() => {
        eventsService.registerEvent({
          name: Events.PLAYER_RATE_CHANGED,
          event: videoElement.playbackRate
        })
        Sentry.setTag('playbackRate', videoElement.playbackRate)
      });
      return () => SpeedSubscription.unsubscribe();
    }
  }, [videoElementRef])
}

import { useEffect } from 'react';
import { filter, fromEvent } from 'rxjs';

import { Events, eventsService } from '../services/events';

export const useKeyPressEvent = () => {
  useEffect(() => {
    const keyPressSubscription = fromEvent<KeyboardEvent>(document, 'keydown').pipe(
      filter(({ key }) => new Set([' ', 'k', 'f', 'm', 'arrowup', 'arrowdown', 'arrowleft', 'arrowright']).has(key.toLocaleLowerCase()))
    ).subscribe(() => eventsService.registerEvent({ name: Events.PLAYER_KEY_PRESSED }))
    return () => keyPressSubscription.unsubscribe()
  }, []);
}

import { BehaviorSubject, tap } from 'rxjs';

import { Actions, controlsService } from './controls';
import { seekTimeService } from './seekTime';

class RemoteControlService {
  private remoteControlMode$ = new BehaviorSubject<boolean>(false);

  getRemoteControlMode() {
    return this.remoteControlMode$
      .pipe(tap((isRemoteControlModeActive) => !isRemoteControlModeActive && controlsService.registerAction({
        name: Actions.REQUEST_SEEK,
        data: seekTimeService.getSeekTime()
      })))
  }

  setRemoteControlMode(mode: boolean) {
    this.remoteControlMode$.next(mode);
  }

  getRemoteControlModeValue() {
    return this.remoteControlMode$.value;
  }
}

export const remoteControlService = new RemoteControlService();

import { useEffect, useMemo } from 'react';
import { BehaviorSubject, filter, fromEvent } from 'rxjs';

import { Actions, controlsService } from '../services/controls';
import { volumeService } from '../services/volume';

export const useVolumeShortcut = () => {
  const volume$ = useMemo(() => new BehaviorSubject(0), [])

  useEffect(() => {
    const volumeSubscription = volumeService.listenVolume().subscribe((newVolume) => volume$.next(newVolume))
    return () => volumeSubscription.unsubscribe();
  }, [volume$])

  useEffect(() => {
    const keyDownEvent$ = fromEvent<KeyboardEvent>(document, 'keydown')
    const keyUpSubsription = keyDownEvent$.pipe(filter(({ key }) => key === 'ArrowUp')).subscribe(() => {
      if (volume$.value + 5 > 100) {
        controlsService.registerAction({ name: Actions.CLICK_VOLUME_CHANGE, data: 100 });
      } else {
        controlsService.registerAction({ name: Actions.CLICK_VOLUME_CHANGE, data: volume$.value + 5 });
      }
    })

    const keyDownSubscription = keyDownEvent$.pipe(filter(({ key }) => key === 'ArrowDown')).subscribe(() => {
      if (volume$.value - 5 < 0) {
        controlsService.registerAction({ name: Actions.CLICK_VOLUME_CHANGE, data: 0 });
      } else {
        controlsService.registerAction({ name: Actions.CLICK_VOLUME_CHANGE, data: volume$.value - 5 });
      }
    })

    return () => {
      keyUpSubsription.unsubscribe();
      keyDownSubscription.unsubscribe();
    }
  }, [volume$])
}

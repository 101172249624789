import PauseIcon from '../../../../../../icons/Pause';
import PlayIcon from '../../../../../../icons/Play';
import ReplayIcon from '../../../../../../icons/Replay';

interface IconProps {
  isPlaying: boolean | null | undefined;
}

const Icon: React.FC<IconProps> = ({ isPlaying }) => {
  if (!!isPlaying) return <PauseIcon />;
  if (isPlaying === undefined) return <ReplayIcon />;
  return <PlayIcon />;
}

export default Icon;

import { memo } from 'react';

const RequestPiPIcon = () => {
  return (
    <div>
      <svg
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
      >
        <path
          fill='currentColor'
          d="M18.5,10.9H12c-0.6,0-1.1,0.5-1.1,1.1v4.4c0,0.6,0.5,1.1,1.1,1.1h6.5c0.6,0,1.1-0.5,1.1-1.1V12
    C19.6,11.4,19.1,10.9,18.5,10.9z M24,19.6V4.3c0-1.2-1-2.2-2.2-2.2H2.2C1,2.2,0,3.1,0,4.3v15.3c0,1.2,1,2.2,2.2,2.2h19.6
    C23,21.8,24,20.8,24,19.6z M20.7,19.7H3.3c-0.6,0-1.1-0.5-1.1-1.1V5.4c0-0.6,0.5-1.1,1.1-1.1h17.5c0.6,0,1.1,0.5,1.1,1.1v13.1
    C21.8,19.2,21.3,19.7,20.7,19.7z"
        />
      </svg>
    </div>
  )
}

export default memo(RequestPiPIcon);

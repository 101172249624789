import React from "react";

import { TCurrentErrorType } from "../../../../interfaces/errorHandler";
import WhiteNoise from "../WhiteNoise";
import ErrorIcon from "./ErrorIcon";

interface IObjectLiteral {
  [key: string]: any;
}

interface IProps {
  currentErrorType: TCurrentErrorType;
  currentErrorMessage: string;
}

const ErrorOverlay: React.FC<IProps> = (props: IProps) => {
  const { currentErrorType, currentErrorMessage } = props;

  const gandalf = window.location.origin + "/gandalf.gif";
  console.log(
    "%c ",
    `padding:200px 480px 0 0; background:url(${gandalf}) no-repeat;`
  );

  const defineErrorMessage = () => {
    if (currentErrorType === "api") {
      const messages: IObjectLiteral = {
        notFound:
          "Vídeo não encontrado. Verifique o link ou tente outro vídeo.",
        unauthorized:
          "Você não tem autorização para acessar este vídeo. Verifique suas permissões ou entre em contato com o suporte.",
        paymentRequired:
          "Vídeo indisponível. Verifique suas permissões ou entre em contato com o suporte.",
        badToken: "token de acesso inválida. Faça login novamente.",
        deviceNotSupported:
          "Dispositivo não suportado. Verifique a compatibilidade do dispositivo utilizado.", // TODO: Adicionar Link para FAQ quando existir
        originIsUnreachable: "dispositivo desconectado. Verifique sua conexão.",
        error:
          "Não foi possível processar o vídeo. Por favor, faça o upload novamente. Se o problema persistir, entre em contato com o suporte.",
        defaultError: "",
        preconditionFailed:
          "Domínio não autorizado. Verifique se o URL está correto ou entre em contato com o suporte.",
      };

      return messages[currentErrorMessage];
    }

    if (currentErrorType === "hls") {
      const messages: IObjectLiteral = {
        "[MEDIA_ERROR: bufferStalledError]":
          "O vídeo travou ao mudar a qualidade. Tente reiniciar o vídeo ou selecionar uma qualidade diferente.",
        "[MEDIA_ERROR: bufferNudgeOnStall]":
          "o vídeo está travado. Tente recarregar a página.",
      };

      if (messages[currentErrorMessage]) return messages[currentErrorMessage];
    }

    return currentErrorMessage;
  };

  const errorMessage = defineErrorMessage();

  return (
    <WhiteNoise>
      <section
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, .8)",
          fontFamily: "Roboto, sans-serif",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <ErrorIcon fill="#FF655F" height="3.25em" />

          <div
            style={{
              textAlign: "center",
              color: "#dedede",
              marginTop: 16,
            }}
          >
            <strong
              style={{
                fontSize: "1.5em",
                display: "block",
              }}
            >
              {currentErrorMessage === "error" && "Processamento interrompido!"}
              {currentErrorMessage !== "error" && "Parece que algo deu errado!"}
            </strong>
            <small
              style={{
                fontSize: "1.25em",
                display: "block",
                overflowWrap: "break-word",
                inlineSize: "30rem",
              }}
            >
              {errorMessage}
            </small>
          </div>
        </div>
      </section>
    </WhiteNoise>
  );
};

export default ErrorOverlay;

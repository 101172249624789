import { useCallback, useMemo } from 'react';
import { useObservable } from 'react-use-observable';

import { Actions, controlsService } from '../../../../../../services/controls';
import { volumeService } from '../../../../../../services/volume';
import VolumeOffIcon from '../../../../../icons/VolumeOff';
import VolumeUpIcon from '../../../../../icons/VolumeUp';
import Button from '../../Controls/Button';

const Mute: React.FC = () => {
  const [isMuted] = useObservable(() => volumeService.listenIsMuted(), []);
  const soundIcon = useMemo(() => {
    if (isMuted) {
      return <VolumeOffIcon />
    }
    return <VolumeUpIcon />
  }, [isMuted])

  const registerAction = useCallback(() => {
    controlsService.registerAction({ name: Actions.CLICK_MUTED, data: !isMuted });
  }, [isMuted]);

  return (
    <Button onClick={() => registerAction()}>
      {soundIcon}
    </Button>
  );
}

export default Mute;

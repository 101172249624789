import './style.css';

import React from 'react';

interface IBackdropProps {
  position: 'top' | 'bottom';
}

const Backdrop: React.FC<IBackdropProps> = ({ position }) => {
  return (
    <svg
      width="100%"
      className="backdropSVG"
      style={{ maxHeight: '3.6rem' }}
    >
      <defs>
        <linearGradient id="backdrop-top" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style={{ stopColor: 'rgba(0, 0, 0, 0.7)' }} />
          <stop offset="100%" style={{ stopColor: 'rgba(0, 0, 0, 0)' }} />
        </linearGradient>
        <linearGradient id="backdrop-bottom" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style={{ stopColor: 'rgba(0, 0, 0, 0)' }} />
          <stop offset="100%" style={{ stopColor: 'rgba(0, 0, 0, 0.7)' }} />
        </linearGradient>
      </defs>
      <rect
        width="100%"
        height='3.6rem'
        fill={`url(#backdrop-${position})`}
      />
    </svg>
  )
}

export default Backdrop;

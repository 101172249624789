class SeekTimeService {
  private seekTime = 0;

  setSeekTime(newTime: number) {
    this.seekTime = newTime;
  }

  getSeekTime() {
    return this.seekTime;
  }
}

export const seekTimeService = new SeekTimeService();
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';
import { ENV, SENTRY_DSN, SENTRY_RATE } from './config';

const ignoreErrors = (): string[] | RegExp[] => {
  if (ENV === 'prod') {
    return [
      /NotAllowedError: play() can only be initiated by a user gesture/,
      /request was interrupted by a call to pause/,
      /failed because the user didn't interact with the document first/,
      /AbortError: The operation was aborted/,
      /Can't find variable: ResizeObserver/,
      /ResizeObserver is not defined/,
      /AbortError: The play() request was interrupted because video-only background media was paused to save power./,
      /PresentationConnection/
    ];
  }
  return [];
};

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENV,
  ignoreErrors: ignoreErrors(),
  sampleRate: parseFloat(SENTRY_RATE),
  denyUrls: [
    /^extensions\//i,
    /^chrome-extension:\/\//i
  ],
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// reportWebVitals();

import './style.css';

import React from 'react';
import { useObservable } from 'react-use-observable';
import { interval, map, startWith, switchMap, take } from 'rxjs';

import { Actions, controlsService } from '../../../../../../../services/controls';
import PauseIcon from '../../../../../../icons/Pause';
import PlayIcon from '../../../../../../icons/Play';

interface IPlayPauseIndicatorProps {
  actionToShow: Actions
}

const PlayPauseIndicator: React.FC<IPlayPauseIndicatorProps> = ({ actionToShow }) => {
  const [show] = useObservable(() => controlsService.on(actionToShow).pipe(
    switchMap(() => interval(500).pipe(
      map(() => false),
      startWith(true),
      take(2)
    ))
  ), [actionToShow])

  if (actionToShow === Actions.CLICK_PLAY) {
    return <PlayIcon className={`hiddenIcon ${show ? 'showIcon' : ''}`} />
  }
  return <PauseIcon className={`hiddenIcon ${show ? 'showIcon' : ''}`} />
}

export default PlayPauseIndicator;

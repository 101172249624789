import { useObservable } from 'react-use-observable';
import { pluck } from 'rxjs';

import { ISubtitle } from '../../videozz-common/dtos/player/ISubtitle';
import { watchService } from '../services/watch';

export const useSubtitles = () => {
  const [subtitles] = useObservable<ISubtitle[] | undefined>(() => watchService.getWatchInfo().pipe(
    pluck('subtitles')
  ), []);

  return subtitles;
}

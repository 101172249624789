import { useEffect } from 'react';
import { fromEvent, pluck } from 'rxjs';

import IChromecastMediaEvent from '../../interfaces/chromecastMediaEvent';
import { Events, eventsService } from '../../services/events';

export const useChromecastTimeupdateEvents = (playerController: any) => {
  useEffect(() => {
    if (playerController) {
      const castPlayerStateChangedSubscription = fromEvent<IChromecastMediaEvent<number>>(playerController, (window as any).cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED)
        .pipe(pluck('value'))
        .subscribe((currentTime) => {
          eventsService.registerEvent({
            name: Events.PLAYER_TIMEUPDATE,
            event: currentTime
          })
        })

      return () => {
        castPlayerStateChangedSubscription.unsubscribe();
      }
    }
  }, [playerController]);
}

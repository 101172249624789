import { memo } from 'react';

const LogoIcon = () => {
  return (
    <svg version="1.1" id="Camada_1" focusable="false" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24">
      <g id="Layer_2">
        <g id="Layer_1-2">
          <g id="Logo">
            <path
              fill="currentColor"
              d="M9,7c0,0,0.3-1.3,2-3.7c1.8-2.5,2.7-2.8,2.7-2.8L12,0L1.5,3.3c0,0-0.3,3.3-0.3,5c0,1.8,0.1,3.7,0.4,5.5
                c0.8,0.7,1.7,1.3,2.7,1.7C7.1,16.6,9,16.5,9,16.5V7z"
            />
            <path
              fill="currentColor"
              d="M13.7,0.5c0,0-0.9,0.3-2.8,3C9.2,5.9,9,7,9,7l7.9,5.2c0.7,1.1,1.2,2.2,1.5,3.5c0.3,1,0.6,4.3,0.6,4.3s1.8-1.6,2.7-3.3
                c0.9-1.8,1.1-5.8,1.1-8.1c0-1.8-0.3-5.3-0.3-5.3L13.7,0.5z"
            />
            <path
              fill="currentColor"
              d="M1.5,13.8c0,0,2,1.4,3.6,2c1.2,0.4,2.5,0.7,3.8,0.8l7.9-4.3c0,0,0.5,0.4,1.4,2.6c1,2.5,0.7,5.2,0.7,5.2s-2.1,1.6-3.3,2.3
                C14.4,23,12,24,12,24s-4.7-1.8-7.4-4.4C3,18.1,1.9,16,1.5,13.8z"
            />
            <path
              fill="currentColor"
              d="M9,7c0,0,0.1-0.8,1.1-2.4c1.2-2.1,2.8-3.8,3.6-4.1"
            />
            <path
              fill="currentColor"
              d="M9,7c0,0,0.1-0.8,1.1-2.4c1.3-2.1,2.8-3.8,3.6-4.2"
            />
            <path
              fill="currentColor"
              d="M16.9,12.3c0,0,0.6,0.6,1.3,2.3c0.9,2.1,0.9,4.6,0.8,5.4"
            />
            <path
              fill="currentColor"
              d="M16.9,12.3c0.5,0.7,1,1.4,1.3,2.3c0.9,2.3,1,4.7,0.8,5.5"
            />
            <path
              fill="currentColor"
              d="M9,16.5c-3.5-0.1-6.5-2.1-7.5-2.8"
            />
            <path
              fill="currentColor"
              d="M9,16.5c-3.5-0.1-6.5-2-7.5-2.8"
            />
            <path
              fill="currentColor"
              d="M9,7c0,0,2.9-3.7,8.8-5.2l-4.2-1.3C12.6,1,9.7,4.7,9,7z"
            />
            <path
              fill="currentColor"
              d="M16.9,12.3c0,0,1.1,4.7-1.6,10.2c1.2-0.5,2.9-1.9,3.7-2.5C19.3,17.2,18.3,14,16.9,12.3z"
            />
            <path
              fill="currentColor"
              d="M9,16.5c0,0-4.8-2-7.8-7.3c0,1.5,0.1,3.1,0.3,4.6C3.7,15.3,6.2,16.5,9,16.5z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default memo(LogoIcon);

import React, { useEffect } from 'react';

import WhiteNoiseGif from '../../../../assets/white-noise.gif';

interface IProps {
  children?: any;
}

const whiteNoiseStyle = {
  width: '100vw',
  height: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundImage: `url(${WhiteNoiseGif})`,
  opacity: '.6'
}

const WhiteNoise: React.FC<IProps> = ({ children }: IProps) => {
  useEffect(() => {
    const loadingElement = document.querySelector('#loading');
    if (loadingElement) {
      loadingElement.classList.add('fadeOut');
    }
  }, [])

  return (
    <div style={whiteNoiseStyle}>{children}</div>
  );
};

export default WhiteNoise;


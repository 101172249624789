export const BUILD_NUMBER = (process.env.REACT_APP_BUILD_NUMBER || '').trim();
export const BRANCH = (process.env.REACT_APP_BRANCH || '').trim();
export const COMMIT = (process.env.REACT_APP_COMMIT || '').trim();

export const ENV = (process.env.REACT_APP_ENV || '').trim();
export const API_URL = (process.env.REACT_APP_API_URL || '').trim();
export const SENTRY_RATE = (process.env.REACT_APP_SAMPLE_RATE || '').trim();

export const SENTRY_DSN = 'https://160135eec0f547059138031fb75533bd@o154277.ingest.sentry.io/6380265';
export const VIDEOZZ_CRYPTOGRAPHY = 'd9c7ca3d-7316-4119-8594-00aefbbaae81'

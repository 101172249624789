import { useEffect } from "react";
import { fromEvent } from "rxjs";

import { Actions, controlsService } from "../services/controls";
import { Events, eventsService } from "../services/events";
import PlayerService from "../services/player";

export const usePlayPauseActions = ({
  current: playerService,
}: React.MutableRefObject<PlayerService>) => {
  useEffect(() => {
    const playSubscription = controlsService
      .on(Actions.CLICK_PLAY)
      .subscribe(async () => {
        const currentPlayer = playerService.getCurrentPlayerService();
        if (currentPlayer) {
          currentPlayer.play();
        }
      });

    return () => playSubscription.unsubscribe();
  }, [playerService]);

  useEffect(() => {
    const pauseSubscription = controlsService
      .on(Actions.CLICK_PAUSE)
      .subscribe(() => {
        const currentPlayer = playerService.getCurrentPlayerService();
        if (currentPlayer) {
          currentPlayer.pause();
        }
      });

    return () => pauseSubscription.unsubscribe();
  }, [playerService]);
};

export const usePlayPauseEvents = (
  videoElementRef: React.MutableRefObject<HTMLVideoElement | null>
) => {
  useEffect(() => {
    const videoElement = videoElementRef.current;

    if (videoElement) {
      const playEventSubscription = fromEvent(videoElement, "play").subscribe(
        (data) => {
          eventsService.registerEvent({
            name: Events.PLAYER_PLAYED,
            event: data,
          });
        }
      );

      return () => playEventSubscription.unsubscribe();
    }
  }, [videoElementRef]);

  useEffect(() => {
    const videoElement = videoElementRef.current;

    if (videoElement) {
      const pauseEventSubscription = fromEvent(videoElement, "pause").subscribe(
        (data) => {
          eventsService.registerEvent({
            name: Events.PLAYER_PAUSED,
            event: data,
          });
        }
      );

      return () => pauseEventSubscription.unsubscribe();
    }
  }, [videoElementRef]);
};

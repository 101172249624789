import { useCallback } from 'react';
import { useObservable } from 'react-use-observable';

import { Actions, controlsService } from '../../../../../../services/controls';
import { fullscreenService } from '../../../../../../services/fullscreen';
import ExitFullscreenIcon from '../../../../../icons/ExitFullscreen';
import RequestFullscreenIcon from '../../../../../icons/RequestFullscreen';
import Button from '../Button';

const Fullscreen = () => {
  const [isFullscreen] = useObservable(() => fullscreenService.getIsFullscreen(), []);

  const registerAction = useCallback((fullscreenActivated: boolean) => {
    controlsService.registerAction({ name: fullscreenActivated ? Actions.CLICK_EXIT_FULLSCREEN : Actions.CLICK_REQUEST_FULLSCREEN });
  }, []);

  return (
    <Button
      onClick={() => registerAction(!!isFullscreen)}
      disabled={!fullscreenService.getFullscreenEnabled()}
    >
      {isFullscreen ? <ExitFullscreenIcon /> : <RequestFullscreenIcon />}
    </Button>
  );
}

export default Fullscreen;

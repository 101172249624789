import { useEffect } from 'react';
import { fromEvent } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { Events, eventsService } from '../services/events';

export const useDurationEvents = (videoElementRef: React.MutableRefObject<HTMLVideoElement | null>) => {
  useEffect(() => {
    const videoElement = videoElementRef.current;
    if (videoElement) {
      fromEvent(videoElement, 'timeupdate').subscribe((event) => {
        eventsService.registerEvent({
          name: Events.PLAYER_TIMEUPDATE,
          event: (event.target as HTMLVideoElement).currentTime
        })
      });

      fromEvent(videoElement, 'durationchange')
        .pipe(
          filter(event => !!(event.target as HTMLVideoElement).duration),
          take(1)
        )
        .subscribe(() => {
          eventsService.registerEvent({
            name: Events.PLAYER_DURATIONCHANGE,
            event: videoElement.duration
          })
        });
    }
  }, [videoElementRef])
}

import { TLanguage } from './watch';

export enum EQualityMenu {
  "1080p" = 4,
  "720p" = 3,
  "480p" = 2,
  "360p" = 1,
  "240p" = 0,
  "Automático" = -1
}

export enum EPlaybackRateMenu {
  "0.25x" = 0.25,
  "0.50x" = 0.50,
  "0.75x" = 0.75,
  "Normal" = 1,
  "1.25x" = 1.25,
  "1.50x" = 1.50,
  "1.75x" = 1.75,
  "2x" = 2
}

export enum ESubMenu {
  'quality',
  'playbackRate',
  'subtitle'
}

export interface ISubMenuData {
  id: ESubMenu;
  title: string;
  initialValue: number | TLanguage | string;
  list: ISubMenuItem[];
}

export interface ISubMenuItem {
  title: string | TLanguage;
  data: number | string;
}

import { useEffect } from 'react';
import { switchMap } from 'rxjs/operators';

import { chromecastService } from '../../services/chromecast';
import { Events, eventsService } from '../../services/events';

export const useChromecastLoadMedia = (playlistURL: string) => {
  useEffect(() => {
    const loadMediaSubscription = eventsService
      .on(Events.CHROMECAST_CONNECTED)
      .pipe(switchMap(() => chromecastService.loadMedia(playlistURL)))
      .subscribe(() => eventsService.registerEvent({ name: Events.CHROMECAST_LOADED_MEDIA }))

    return () => loadMediaSubscription.unsubscribe();
  }, [playlistURL]);
}

import { memo } from 'react';

const ReplayIcon = () => {
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M12,4.6V1.3c0-0.5-0.6-0.8-1-0.4L6.5,5.3c-0.2,0.2-0.2,0.6,0,0.8l4.4,4.4c0.4,0.4,1,0.1,1-0.4V6.9c4.4,0,7.8,4,6.8,8.5
          c-0.5,2.7-2.7,4.8-5.3,5.3c-4.2,0.9-7.9-2-8.4-5.9c-0.1-0.6-0.6-1-1.1-1c-0.7,0-1.3,0.6-1.2,1.3c0.7,5.1,5.6,8.9,11.1,7.9
          c3.6-0.7,6.6-3.6,7.3-7.3C22.3,9.8,17.7,4.6,12,4.6z"/>
    </svg>
  )
}

export default memo(ReplayIcon);

import {
  buffer,
  bufferCount,
  bufferTime,
  debounceTime,
  filter,
  first,
  fromEvent,
  map,
  merge,
  pluck,
  race,
  repeat,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

import { Actions, controlsService } from './controls';
import { Events, eventsService } from './events';


class ActiveService {
  private active = false;

  constructor() {
    this.listenIsActive().subscribe(isActive => this.active = isActive);
  }

  listenIsActive() {
    return eventsService
      .listenEvents()
      .pipe(
        pluck('name'),
        filter(filteringItem => filteringItem === Events.PLAYER_INACTIVED || filteringItem === Events.PLAYER_ACTIVED),
        map(lastItem => (lastItem !== Events.PLAYER_INACTIVED))
      )
  }

  listenActiveVideoClicksCount() {
    const inactived$ = this.listenIsActive().pipe(filter(isActive => !isActive));
    const actived$ = this.listenIsActive().pipe(filter(isActive => isActive), startWith(true));
    const click$ = controlsService.on(Actions.CLICK_VIDEO_ACTIVE);
    const deboucedClick$ = click$.pipe(debounceTime(200));
    const acionas$ = race(deboucedClick$, click$.pipe(bufferCount(2))).pipe(first(), repeat());

    return actived$.pipe(
      switchMap(() => click$.pipe(
        buffer(acionas$),
        map(clicks => [clicks.length, clicks[0].data] as [number, MouseEvent | TouchEvent]),
        takeUntil(inactived$)
      )));
  }

  listenActiveVideoClicks(clickCount = 1) {
    return this.listenActiveVideoClicksCount().pipe(filter(([activeClicksCount]) => activeClicksCount === clickCount));
  }

  listenMergedUserActionEvents() {
    const generalClick$ = controlsService.on(Actions.CLICK_VIDEO_ACTIVE);
    const clickVideo$ = controlsService.on(Actions.CLICK_VIDEO);
    const mouseMove$ = fromEvent<any>(window, 'mousemove');
    const clickEvent$ = fromEvent<any>(window, 'click');
    const keyPressed$ = eventsService.on(Events.PLAYER_KEY_PRESSED)

    return merge(mouseMove$, generalClick$, clickEvent$, clickVideo$, keyPressed$)
      .pipe(
        tap(() => { if (!this.active) eventsService.registerEvent({ name: Events.PLAYER_ACTIVED }) }),
        bufferTime(2000)
      );
  }
}

export const activeService = new ActiveService();

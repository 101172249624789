class TransferredService {
  private transferred: number = 0;

  setTransferred(newTransferred: number) {
    this.transferred += newTransferred / 8;
  }

  getTransferred() {
    return this.transferred;
  }
}

export const transferredService = new TransferredService();
import { useEffect } from 'react';
import { filter, fromEvent, map, race, switchMap, takeUntil } from 'rxjs';

import { activeService } from '../services/active';
import { Actions, controlsService } from '../services/controls';
import { Events, eventsService } from '../services/events';
import { playPauseService } from '../services/playPause';

export const useVideoClick = (transitionNodeRef: React.RefObject<HTMLElement>) => {
  const getFooterBackdropRectElement = (currentTransitionNodeRef: HTMLElement) => {
    const footerElement = Array.from(currentTransitionNodeRef.children)[1];
    if (!footerElement) {
      return undefined;
    }
    const footerBackdropSVGElement = Array.from(footerElement.children)[0];
    if (!footerElement) {
      return undefined;
    }
    const footerBackdropRectElement = Array.from(footerBackdropSVGElement.children)[1];
    return footerBackdropRectElement;
  }

  useEffect(() => {
    if (transitionNodeRef.current) {
      const touch$ = fromEvent<TouchEvent>(transitionNodeRef.current, 'touchstart');
      const click$ = fromEvent<MouseEvent>(transitionNodeRef.current, 'mousedown');

      const headerElement = Array.from(transitionNodeRef.current.children)[0];
      const headerControlsContainer = Array.from(headerElement.children)[1];
      const headerBackdropSVGElement = Array.from(headerElement.children)[0];
      const headerBackdropRectElement = Array.from(headerBackdropSVGElement.children)[1];

      const footerBackdropRectElement = getFooterBackdropRectElement(transitionNodeRef.current);

      const clickVideoCanvas$ = race(touch$, click$).pipe(
        filter(({ target }) => [transitionNodeRef.current, footerBackdropRectElement, headerControlsContainer, headerBackdropRectElement].includes(target as HTMLElement))
      );
      const closeMenu$ = eventsService.on(Events.PLAYER_MENU_CLOSED);
      const clickMenuClosed$ = closeMenu$.pipe(
        switchMap(() => clickVideoCanvas$.pipe(
          takeUntil(controlsService.on(Actions.REQUEST_OPEN_MENU))
        ))
      );

      const clickVideoEventSubscription = click$.subscribe(() => controlsService.registerAction({ name: Actions.CLICK_VIDEO }));
      const closeMenuSubscription = clickVideoCanvas$.subscribe(() => controlsService.registerAction({ name: Actions.REQUEST_CLOSE_MENU }));
      const togglePlaySubscription = clickMenuClosed$.subscribe(data => controlsService.registerAction({ name: Actions.CLICK_VIDEO_ACTIVE, data }));

      return () => {
        clickVideoEventSubscription.unsubscribe();
        closeMenuSubscription.unsubscribe();
        togglePlaySubscription.unsubscribe();
      };
    }
  }, [transitionNodeRef]);

  useEffect(() => controlsService.registerAction({ name: Actions.CLICK_VIDEO_ACTIVE }), []);

  useEffect(() => {
    const activeVideoClickSubscription = activeService.listenActiveVideoClicks()
      .pipe(map(() => playPauseService.getIsPlayingValue()))
      .subscribe((isPlaying) => controlsService.registerAction({ name: isPlaying ? Actions.CLICK_PAUSE : Actions.CLICK_PLAY })
      )

    return () => activeVideoClickSubscription.unsubscribe();
  }, []);
}

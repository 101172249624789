import Hls, { ErrorData, Events as HlsEvents } from 'hls.js';
import { useEffect } from 'react';
import { filter, fromEvent, merge } from 'rxjs';

import { Events, eventsService } from '../services/events';

export const useHlsBufferLoading = (hls: Hls) => {
  useEffect(() => {
    if (Hls.isSupported()) {
      const hlsErrorEvent$ = fromEvent<HlsEvents, ErrorData>(hls, Hls.Events.ERROR, (_, data: ErrorData) => data);
      const bufferStalled$ = hlsErrorEvent$.pipe(filter(({ details }) => details === Hls.ErrorDetails.BUFFER_STALLED_ERROR));
      const bufferNudgeOnStall$ = hlsErrorEvent$.pipe(filter(({ details }) => details === Hls.ErrorDetails.BUFFER_NUDGE_ON_STALL));

      const hlsFragLoadingSubscription = merge(bufferStalled$, bufferNudgeOnStall$)
        .subscribe(() => eventsService.registerEvent({ name: Events.PLAYER_LOADSTART }));

      return () => hlsFragLoadingSubscription.unsubscribe();
    }
  }, [hls]);

  useEffect(() => {
    if (Hls.isSupported()) {
      const hlsFragLoadedSubscription = fromEvent(hls, Hls.Events.FRAG_BUFFERED)
        .subscribe(() => eventsService.registerEvent({ name: Events.PLAYER_LOADEND }));

      return () => hlsFragLoadedSubscription.unsubscribe();
    }
  }, [hls]);
}

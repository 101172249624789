import { BehaviorSubject } from 'rxjs';

class PipService {
  private pipEnable = new BehaviorSubject<boolean>(false);

  changePiP(isPiP: boolean) {
    this.pipEnable.next(isPiP);
  }

  listenIsPiPEnabled() {
    return this.pipEnable.asObservable();
  }
}

export const pipService = new PipService();

import './style.css';

import { useEffect, useState } from 'react';
import { bufferTime, filter, fromEvent, pluck } from 'rxjs';

const Arrazo: React.FC = () => {
  const [showArrazo, setShowArrazo] = useState(false);

  useEffect(() => {
    const keyDownEvent$ = fromEvent<KeyboardEvent>(document, 'keydown');
    const arrazoEvent$ = keyDownEvent$.pipe(
      pluck('key'),
      filter((key) => new Set(['=', ']']).has(key.toLocaleLowerCase())),
      bufferTime(250),
      filter((keys) => {
        if (keys.length !== 2) {
          return false;
        }
        if (keys[0] === '=' && keys[1] === ']') {
          return true;
        }
        return false;
      })
    );
    const arrazoEventSubscription = arrazoEvent$.subscribe(() => setShowArrazo(true));
    return () => arrazoEventSubscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (!showArrazo) {
      return;
    }
    setTimeout(() => setShowArrazo(false), 4000);
  }, [showArrazo]);

  if (!showArrazo) {
    return <></>;
  }

  return (
    <div className="arrazoContainer">
      <svg viewBox="0 0 100 100">
        <g className="eyes">
          <g className="eyes__item">
            <path className="path" d="M 40, 40 l 0, -20" stroke-width="5" />
            <path d="M 40, 40 l 0, 20" stroke-width="5" />
          </g>
          <g className="eyes__item">
            <path d="M 60, 40 l 0, -20" stroke-width="5" />
            <path d="M 60, 40 l 0, 20" stroke-width="5" />
          </g>
        </g>
        <g className="mouth">
          <path d="M 20,60 l 0,20" stroke-width="5" />
          <path d="M 20,75 l 60,0" stroke-width="10" />
          <path d="M 80,60 l 0,20" stroke-width="5" />
        </g>
      </svg>
    </div>
  );
}

export default Arrazo;

import MobileDetect from 'mobile-detect';
import { useCallback, useEffect } from 'react';
import { fromEvent } from 'rxjs';

import { Events, eventsService } from '../services/events';

export const useLoading = ({ current: videoElement }: React.MutableRefObject<HTMLVideoElement | null>) => {
  const enableLoadingEventOnIOS = useCallback((currentVideoElement: HTMLVideoElement) => {
    const mobileDetector = new MobileDetect(window.navigator.userAgent);
    if (mobileDetector.is('iOS')) {
      currentVideoElement.autoplay = true;
    }
  }, []);

  useEffect(() => {
    if (videoElement) {
      const loadstartEventSubscription = fromEvent(videoElement, 'loadstart')
        .subscribe(() => eventsService.registerEvent({ name: Events.PLAYER_LOADSTART }));

      return () => loadstartEventSubscription.unsubscribe();
    }
  }, [videoElement]);

  useEffect(() => {
    if (videoElement) {
      enableLoadingEventOnIOS(videoElement);

      const loadendEventSubscription = fromEvent(videoElement, 'loadeddata')
        .subscribe(() => eventsService.registerEvent({ name: Events.PLAYER_LOADEND }));

      return () => loadendEventSubscription.unsubscribe();
    }
  }, [videoElement, enableLoadingEventOnIOS]);
}

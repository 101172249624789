import { ISubtitle } from '../../videozz-common/dtos/player/ISubtitle';
import { ISubMenuItem } from '../interfaces/menu';
import { ELanguages, TLanguage } from '../interfaces/watch';
import { useSubtitles } from './subtitles';

export const useMenuSubtitles = () => {
  const subtitles = useSubtitles();
  if (subtitles?.length === 0) return null;

  const menuSubtitles = subtitles?.map(({ language, autogenerated }: ISubtitle) => {
    const title = `${ELanguages[language as TLanguage]} ${autogenerated ? ' (automática)' : ''}`;
    const languageCode = autogenerated ? 'au-To' : language;
    return ({ title, data: languageCode } as ISubMenuItem);
  })
  return [{ title: 'Desativado', data: '' }, ...menuSubtitles || []] as ISubMenuItem[];
}

import { useEffect } from 'react';
import { useObservable } from 'react-use-observable';
import { fromEvent, take } from 'rxjs';

import IChromecastMediaEvent from '../../interfaces/chromecastMediaEvent';
import { chromecastService } from '../../services/chromecast';
import { Events, eventsService } from '../../services/events';
import { useChromecastLoadMedia } from './chromecastLoadMedia';
import { useChromecastPlayPauseEvents } from './chromecastPlayPause';
import { useChromecastReplay } from './chromecastReplay';
import { useChromecastTimeupdateEvents } from './chromecastTimeupdate';
import { useChromecastVolume } from './chromecastVolume';

export const useChromecast = (playlistURL: string) => {
  const [playerController] = useObservable(() => chromecastService.listenPlayerController(), []);

  useChromecastLoadMedia(playlistURL);
  useChromecastReplay(playlistURL);
  useChromecastPlayPauseEvents(playerController);
  useChromecastTimeupdateEvents(playerController);
  useChromecastVolume(playerController);

  useEffect(() => {
    eventsService
      .on(Events.CHROMECAST_CONNECTED)
      .pipe(take(1))
      .subscribe(() => chromecastService.setCast(true))
  }, []);

  useEffect(() => {
    if (playerController) {
      const castAnyEventSubscription = fromEvent<IChromecastMediaEvent<number>>(playerController, (window as any).cast.framework.RemotePlayerEventType.ANY_CHANGE)
        .subscribe((anyEvent) => console.log('ANY_CHANGE ->', anyEvent))

      return () => {
        castAnyEventSubscription.unsubscribe();
      }
    }

  }, [playerController]);
}

import IPlayerService from '../interfaces/playerService';

export default class HlsPlayerService implements IPlayerService {
  playPromise: Promise<void> | undefined;
  constructor(readonly videoElementRef: React.MutableRefObject<HTMLVideoElement | null>) { }

  public play = () => {
    if (this.videoElementRef.current) {
      this.playPromise = this.videoElementRef.current.play();
    }
  }

  public pause = () => {
    if (this.videoElementRef.current && this.playPromise !== undefined) {
      this.playPromise.then(_ => {
        this.videoElementRef.current?.pause();
      }).catch(_ => {
        console.log('error');
      })
    }
  }

  public seek = (desiredSeektime: number) => {
    if (this.videoElementRef.current) {
      this.videoElementRef.current.currentTime = desiredSeektime;
    }
  }

  public mute = (isMuted: boolean) => {
    if (this.videoElementRef.current) {
      this.videoElementRef.current.muted = isMuted;
    }
  }

  public volume = (desiredVolumeLevel: number) => {
    if (this.videoElementRef.current) {
      const formatedDesiredVolumeLevel = desiredVolumeLevel * 0.01;
      this.videoElementRef.current.volume = formatedDesiredVolumeLevel;
    }
  }
}

import { memo } from 'react';

const VolumeUpIcon = () => {
  return (
    <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M0,9.3v5.3C0,15.4,0.6,16,1.3,16h4l4.4,4.4c0.8,0.8,2.3,0.2,2.3-0.9V4.5c0-1.2-1.4-1.8-2.3-0.9L5.3,8h-4C0.6,8,0,8.6,0,9.3z
     M18,12c0-2.4-1.4-4.4-3.3-5.4v10.7C16.6,16.4,18,14.4,18,12z M14.7,1.9v0.3c0,0.5,0.3,0.9,0.8,1.1c3.4,1.4,5.9,4.7,5.9,8.7
    s-2.4,7.3-5.9,8.7c-0.5,0.2-0.8,0.6-0.8,1.1v0.3c0,0.8,0.8,1.4,1.6,1.1C20.8,21.5,24,17.1,24,12s-3.2-9.5-7.7-11.2
    C15.5,0.5,14.7,1.1,14.7,1.9z"
      />
    </svg>
  )
}

export default memo(VolumeUpIcon);

import { BehaviorSubject } from 'rxjs';

import { ICurrentError, TErrorDescriptions } from '../interfaces/errorHandler';

class ErrorHandlerService {
  private currentError$ = new BehaviorSubject<ICurrentError | null>(null);

  defineCurrentError(error: ICurrentError | null) {
    this.currentError$.next(error);
  }

  listenCurrentError() {
    return this.currentError$.asObservable();
  }

  getErrorDescriptionByErrorCode(errorCode: number) {
    let errorCodesDescriptions: TErrorDescriptions[] = [];
    errorCodesDescriptions[404] = 'notFound';
    errorCodesDescriptions[401] = 'unauthorized';
    errorCodesDescriptions[402] = 'paymentRequired';
    errorCodesDescriptions[403] = 'badToken';
    errorCodesDescriptions[412] = 'preconditionFailed';
    errorCodesDescriptions[417] = 'processing';
    errorCodesDescriptions[422] = 'error';
    errorCodesDescriptions[523] = 'originIsUnreachable';
    errorCodesDescriptions[601] = 'deviceNotSupported';
    errorCodesDescriptions[600] = 'defaultError';

    if (errorCodesDescriptions[errorCode] !== undefined) {
      return errorCodesDescriptions[errorCode];
    }

    return 'defaultError';
  }
}

export const errorHandlerService = new ErrorHandlerService();

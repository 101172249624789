import { useCallback, useEffect } from 'react';
import { fromEvent } from 'rxjs';

import { Actions, controlsService } from '../services/controls';
import { Events, eventsService } from '../services/events';
import { pipService } from '../services/pip';

export const usePiPActions = (videoElementRef: React.MutableRefObject<HTMLVideoElement | null>) => {
  const requestPiP = useCallback(() => {
    if (videoElementRef.current)
      videoElementRef.current.requestPictureInPicture();
  }, [videoElementRef]);

  const exitPiP = useCallback(() => {
    if (videoElementRef.current && document.pictureInPictureElement) {
      document.exitPictureInPicture();
    }
  }, [videoElementRef]);

  useEffect(() => {
    controlsService.on(Actions.CLICK_REQUEST_PIP).subscribe(() => {
      requestPiP();
    });

    controlsService.on(Actions.CLICK_EXIT_PIP).subscribe(() => {
      exitPiP();
    });
  }, [requestPiP, exitPiP]);
}

export const usePiPEvents = (videoElementRef: React.MutableRefObject<HTMLVideoElement | null>) => {
  useEffect(() => {
    const videoElement = videoElementRef.current;

    if (videoElement) {
      fromEvent(videoElement, 'enterpictureinpicture').subscribe((data) => {
        eventsService.registerEvent({
          name: Events.PLAYER_PIP_CHANGED,
          event: data
        });
        pipService.changePiP(true);
      });

      fromEvent(videoElement, 'leavepictureinpicture').subscribe((data) => {
        eventsService.registerEvent({
          name: Events.PLAYER_PIP_CHANGED,
          event: data
        });
        pipService.changePiP(false);
      });
    }
  }, [videoElementRef]);
}

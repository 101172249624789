import Hls from 'hls.js';
import { useCallback, useEffect } from 'react';
import { fromEvent } from 'rxjs';

import { Actions, controlsService } from '../services/controls';
import { Events, eventsService } from '../services/events';

export const useHlsNextLevelCatcher = (hls: Hls) => {
  const setNextLevel = useCallback((level: number) => {
    hls.nextLevel = level;
  }, [hls]);

  useEffect(() => {
    const levelSubscription = controlsService.on(Actions.CLICK_LEVEL_CHANGE).subscribe(({ data }) => {
      setNextLevel(data);
    })
    return () => levelSubscription.unsubscribe();
  }, [setNextLevel]);
}

export const useHlsLevels = (hls: Hls) => {
  useEffect(() => {
    if (Hls.isSupported()) {
      const hlsManifestSubscription = fromEvent(hls, Hls.Events.MANIFEST_PARSED).subscribe(data => {
        eventsService.registerEvent({
          name: Events.PLAYER_MANIFEST_PARSED,
          event: data[1]
        })
      })
      return () => hlsManifestSubscription.unsubscribe();
    }
  }, [hls])
}

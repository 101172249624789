import { memo } from 'react';

interface IPauseIconProps {
  className?: string;
}

const PauseIcon: React.FC<IPauseIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path
        fill="currentColor"
        d="M5.6,23.3c1.8,0,3.2-1.4,3.2-3.2V4c0-1.8-1.5-3.3-3.2-3.3S2.4,2.2,2.4,4v16.1C2.4,21.8,3.8,23.3,5.6,23.3z M15.2,4v16.1
    c0,1.8,1.4,3.2,3.2,3.2s3.2-1.4,3.2-3.2V4c0-1.8-1.4-3.2-3.2-3.2S15.2,2.2,15.2,4z" />
    </svg>
  )
}

export default memo(PauseIcon);


import { useEffect } from 'react';
import { filter, map, pluck } from 'rxjs';

import { activeService } from '../services/active';
import { Actions, controlsService } from '../services/controls';
import PlayerService from '../services/player';
import { seekTimeService } from '../services/seekTime';

export const useFastActions = (playerService: React.MutableRefObject<PlayerService>) => {
  useEffect(() => {
    const fastBackwardActionSubscription = controlsService.on<number>(Actions.REQUEST_SKIP_BACKWARD)
      .pipe(pluck('data'))
      .subscribe((data) => {
        playerService.current.getCurrentPlayerService().seek(data || 0)
      })
    return () => fastBackwardActionSubscription.unsubscribe();
  }, [playerService])

  useEffect(() => {
    const fastFowardActionSubscription = controlsService.on<number>(Actions.REQUEST_SKIP_FORWARD)
      .pipe(pluck('data'))
      .subscribe((data) => {
        playerService.current.getCurrentPlayerService().seek(data || 0)
      })
    return () => fastFowardActionSubscription.unsubscribe();
  }, [playerService])

  useEffect(() => {
    const doubleTouch$ = activeService.listenActiveVideoClicks(2).pipe(
      pluck(1),
      filter((touchEvent) => !!(touchEvent as TouchEvent).targetTouches),
      map((touchEvent) => (touchEvent as TouchEvent).targetTouches[0] as Touch)
    )

    const backwardSubscription = doubleTouch$.pipe(
      filter(({ pageX }) => pageX < (window.innerWidth / 2)),
    ).subscribe(() => {
      controlsService.registerAction({ name: Actions.REQUEST_SKIP_BACKWARD, data: seekTimeService.getSeekTime() - 5 })
    });
    const fowardSubscription = doubleTouch$.pipe(
      filter(({ pageX }) => pageX > (window.innerWidth / 2)),
    ).subscribe(() => {
      controlsService.registerAction({ name: Actions.REQUEST_SKIP_FORWARD, data: seekTimeService.getSeekTime() + 5 })
    });

    return () => {
      fowardSubscription.unsubscribe()
      backwardSubscription.unsubscribe()
    }
  }, [])
}

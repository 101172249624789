import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useObservable } from 'react-use-observable';
import { tap } from 'rxjs';

import { IWatch } from '../../../../videozz-common/dtos/player/IWatch';
import { BUILD_NUMBER } from '../../../config';
import { errorHandlerService } from '../../../services/errorHandler';
import { progressService } from '../../../services/progress';
import { watchService } from '../../../services/watch';
import ErrorOverlay from './ErrorOverlay';
import Player from './Player';
import ProcessOverlay from './ProcessOverlay';

export interface IParams {
  tokenKey?: string; //GUID
  playerToken?: string; //JWT
}

const Watch: React.FC = () => {
  const { tokenKey, playerToken } = useParams<IParams>();
  const history = useHistory();

  const [watchInfo] = useObservable<IWatch>(() => watchService.getWatchInfo().pipe(
    tap((info) => progressService.setSessionToken(info.sessionToken)),
    tap((info) => {
      Sentry.setTag('producerAccountId', info.producerAccountId);
      Sentry.setTag('producerEduzzId', info.producerEduzzId);
      Sentry.setTag('sessionPartner', info.partner);
      Sentry.setTag('sessionKey', info.sessionKey);
      Sentry.setTag('videoKey', info.videoKey);
      Sentry.setTag('tokenKey', info.tokenKey);
      Sentry.setTag('playerVersion', BUILD_NUMBER);
    })
  ), []);

  const [currentError] = useObservable(() => errorHandlerService.listenCurrentError(), []);

  const defineWatchInfo = () => {
    watchService.defineWatchInfo(tokenKey || playerToken || '', document.referrer.split('/')[2])
  }

  const defineTokenType = () => {
    if (tokenKey) {
      watchService.setTokenType('embed')
    } else if (playerToken && history.location.pathname.split('/')[1] === 'direct') {
      watchService.setTokenType('direct')
    } else {
      watchService.setTokenType('watch')
    }
  }

  useEffect(defineTokenType, [history, playerToken, tokenKey])
  useEffect(defineWatchInfo, [playerToken, tokenKey])

  if (watchInfo && !currentError) {
    return <Player watchInfo={watchInfo} />;
  }

  if (currentError && currentError.type === 'api' && currentError.data === 'processing') {
    return <ProcessOverlay />;
  }

  if (currentError) {
    return <ErrorOverlay currentErrorType={currentError.type} currentErrorMessage={currentError.data} />;
  }

  return <></>;
}

export default Watch;

import { memo } from 'react';

const SkipRight = () => {
  return <svg focusable="false" viewBox="0 0 92 32" fill="none" >
    <path opacity="0.7" fillRule="evenodd" clipRule="evenodd" d="M34.8727 0.30051C33.3772 -0.553964 31.5088 0.515504 31.5088 2.22603V29.774C31.5088 31.4845 33.3772 32.554 34.8727 31.6995L58.9806 17.9255C60.4775 17.0703 60.4775 14.9297 58.9806 14.0745L34.8727 0.30051Z" fill="#B0BEC5" />
    <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M4.3239 0.30051C2.82836 -0.553964 0.959961 0.515504 0.959961 2.22603V29.774C0.959961 31.4845 2.82836 32.554 4.3239 31.6995L28.4318 17.9255C29.9287 17.0703 29.9287 14.9297 28.4318 14.0745L4.3239 0.30051Z" fill="#B0BEC5" />
    <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M65.7729 0.30051C64.2773 -0.553964 62.4089 0.515504 62.4089 2.22603V29.774C62.4089 31.4845 64.2773 32.554 65.7729 31.6995L89.8808 17.9255C91.3777 17.0703 91.3777 14.9297 89.8808 14.0745L65.7729 0.30051Z" fill="#B0BEC5" />
  </svg>
}

export default memo(SkipRight);

import { BehaviorSubject, catchError, from, of, switchMap, tap } from "rxjs";

import { Events, eventsService } from "./events";
import { seekTimeService } from "./seekTime";

class ChromecastService {
  private cast: boolean = false;
  private player: any;
  private playerController$ = new BehaviorSubject<any>(null);

  getCast() {
    return this.cast;
  }

  setCast(value: boolean) {
    this.cast = value;
  }

  getPlayer() {
    return this.player;
  }

  getPlayerControllerValue() {
    return this.playerController$.value;
  }

  loadMedia(playlistURL: string) {
    const castInstance = (window as any).cast;
    const chromeInstance = (window as any).chrome;
    const castSession =
      castInstance.framework.CastContext.getInstance().getCurrentSession();

    const mediaInfo = {
      ...new chromeInstance.cast.media.MediaInfo(
        playlistURL,
        "application/vnd.apple.mpegurl"
      ),
      metadata: {
        ...new chromeInstance.cast.media.GenericMediaMetadata(),
        metadataType: chromeInstance.cast.media.MetadataType.GENERIC,
      },
      streamType: chromeInstance.cast.media.StreamType.BUFFERED,
    };

    const request = {
      ...new chromeInstance.cast.media.LoadRequest(mediaInfo),
      autoplay: true,
      currentTime: seekTimeService.getSeekTime(),
    };

    return from(castSession.loadMedia(request)).pipe(
      catchError(() =>
        of(
          "não foi possível carregar a mídia no Chromecast. Tente reiniciar o dispositivo e carregar a mídia novamente."
        )
      )
    );
  }

  listenPlayerController() {
    return eventsService.on(Events.CHROMECAST_LOADED_MEDIA).pipe(
      switchMap(() => {
        const castInstance = (window as any).cast;
        const player = new castInstance.framework.RemotePlayer();
        const playerController =
          new castInstance.framework.RemotePlayerController(player);

        this.player = player;
        this.playerController$.next(playerController);

        return this.playerController$.asObservable();
      }),
      tap(() =>
        eventsService.registerEvent({
          name: Events.PLAYER_VOLUME_CHANGED,
          event: {
            muted: this.player.isMuted,
            volume: this.player.volumeLevel,
          },
        })
      )
    );
  }
}

export const chromecastService = new ChromecastService();

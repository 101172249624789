import { map, pluck } from 'rxjs';

import { IVolumeChangeEventData } from '../interfaces/volumechange';
import { Events, eventsService } from './events';

class VolumeService {
  listenVolume() {
    return eventsService.on<IVolumeChangeEventData>(Events.PLAYER_VOLUME_CHANGED).pipe(
      pluck('event'),
      map((data) => data ? data.volume * 100 : 100)
    )
  }

  listenIsMuted() {
    return eventsService.on<IVolumeChangeEventData>(Events.PLAYER_VOLUME_CHANGED).pipe(
      pluck('event'),
      map((data) => data ? data.muted : false)
    )
  }
}

export const volumeService = new VolumeService();

import Hls from 'hls.js';

import { useBufferEvents } from './buffer';
import { useHlsBufferLoading } from './hlsBufferLoading';
import { useHlsLevels, useHlsNextLevelCatcher } from './hlsLevels';

export const useHlsEventCatcher = (hlsInstance: Hls) => {
  useBufferEvents(hlsInstance);
  useHlsLevels(hlsInstance);
  useHlsNextLevelCatcher(hlsInstance);
  useHlsBufferLoading(hlsInstance);
}
